import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';

const loginHeaderStyle = {
  title: css`
    font-size: 26px;
    padding-top: 10px;
  `,
  img: css`
    width: auto;
    height: 40px;
  `,
  logoContainer: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
};

export const HeaderButton = styled(Button)`
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #fff !important;
  transition: opacity 0.2s !important;

  &:hover {
    opacity: 0.8;
  }

  svg {
    width: 24px;
    height: 24px;
    padding: 0 4px 0 0;
  }
`;

export const HeaderLogoutButton = styled(HeaderButton)`
  color: #219ce6 !important;
  font-size: 16px !important;
  font-weight: bold !important;
`;

export default loginHeaderStyle;
