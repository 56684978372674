import { Roles } from 'enums/roles';

/**
 * Return whether user roles match route roles
 *
 * @param {Roles} routeRoles - Route roles
 * @param {Roles} userRoles - User roles
 * @returns {boolean} Either true or false
 */
export const checkRoles = (
  routeRoles: Roles[] = [],
  userRoles: Roles[] = [],
): boolean => {
  return routeRoles.reduce(
    (previous: boolean, current: Roles) =>
      userRoles.includes(current) || previous,
    false,
  );
};
