/**
 * Removed excess digits from application version
 *
 * @param version - Current application version
 * @param digits - How many parts of semantic versioning should be displayed
 * @returns Version spliced to digits amount
 */
export const versionDigits = (
  version?: string,
  digits = 3,
): string | undefined => {
  if (!version) {
    return version;
  }

  const regexp = /\.|-/;
  const splitVersionArray = version.split(regexp);
  const splicedVersionArray = splitVersionArray.splice(0, digits);
  const splicedVersion = splicedVersionArray.join('.');

  return splicedVersion;
};
