import LoginHeader from 'components/LoginHeader/LoginHeader';
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { loginRoutes } from './routes';

class LoginLayoutComponent extends Component {
  render(): JSX.Element {
    return (
      <>
        <LoginHeader />
        {loginRoutes.map((route) => {
          return (
            <Route
              key={route.pathname}
              path={route.pathname}
              component={route.component}
              exact={route.exact}
            />
          );
        })}
      </>
    );
  }
}

export default LoginLayoutComponent;
