/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Button, CircularProgress } from '@material-ui/core';
import CustomLink from 'components/CustomLink/CustomLink';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomField from 'components/Form/Input/CustomField';
import FormTitle from 'components/Form/Title/FormTitle';
import { resetPasswordRouteParameterEnum } from 'enums/resetPasswordRouteParameter';
import { Component, Fragment } from 'react';
import { Field, Form } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { getFetchSpinnerSelector } from 'store/spinner/spinner.selectors';
import { resetPassword, resetUserPassword } from 'store/user/user.actions';
import { IRemindPasswordPayload } from 'store/user/user.actions.types';
import { composeValidators, email, required } from 'utils/customValidators';
import { resetPasswordContainerStyles } from './ResetPasswordContainer.style';

interface IFormValues {
  apiUrl?: string;
  email: string;
}

interface IDispatchProps {
  resetPassword: (payload: IRemindPasswordPayload) => void;
  resetUserPassword: (payload: IRemindPasswordPayload) => void;
}

interface IStateProps {
  fetchSpinner: boolean;
}

interface IMatchProps {
  type: resetPasswordRouteParameterEnum;
}

export class ResetPasswordContainer extends Component<
  WithTranslation &
    IDispatchProps &
    IStateProps &
    RouteComponentProps<IMatchProps>
> {
  onSubmit = ({ email, apiUrl }: IFormValues): void => {
    const {
      match: {
        params: { type },
      },
    } = this.props;

    if (type === resetPasswordRouteParameterEnum.admin) {
      this.props.resetPassword({ email });
    } else if (type === resetPasswordRouteParameterEnum.user) {
      const applicationURL = /^https?:\/\//.test(apiUrl?.trim() || '')
        ? apiUrl?.trim()
        : `https://${apiUrl?.trim()}`;

      this.props.resetUserPassword({ email, apiUrl: applicationURL });
    }
  };

  render(): JSX.Element {
    const {
      t,
      fetchSpinner,
      match: {
        params: { type },
      },
    } = this.props;
    return (
      <FormDiv width={500}>
        <FormTitle text="auth.password.reset" width={500} />
        <span css={resetPasswordContainerStyles.text}>
          {t('auth.password.resetInfo')}
        </span>
        <Form
          onSubmit={this.onSubmit}
          render={({ handleSubmit, submitting }): JSX.Element => (
            <form
              onSubmit={handleSubmit}
              css={resetPasswordContainerStyles.form}
            >
              {type === resetPasswordRouteParameterEnum.user && (
                <Fragment>
                  <Field name="apiUrl" validate={composeValidators([required])}>
                    {({ input, meta }): JSX.Element => (
                      <CustomField
                        label="auth.apiUrl"
                        error={meta.error}
                        touched={meta.touched}
                        inputProps={{ ...input }}
                        testId="apiUrlInput"
                      />
                    )}
                  </Field>
                  <Box height="12px"></Box>
                </Fragment>
              )}
              <Field
                name="email"
                validate={composeValidators([required, email])}
              >
                {({ input, meta }): JSX.Element => (
                  <CustomField
                    label="common.email"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={{ ...input }}
                    testId="emailInput"
                  />
                )}
              </Field>
              <Button
                variant="contained"
                color="secondary"
                disabled={submitting || fetchSpinner}
                type="submit"
                css={resetPasswordContainerStyles.button}
                data-testid="resetButton"
              >
                {fetchSpinner ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  t('common.send')
                )}
              </Button>
            </form>
          )}
        />
        {type === resetPasswordRouteParameterEnum.admin && (
          <CustomLink
            wide
            testId="backLink"
            text="common.cancel"
            linkProps={{ to: '/login' }}
          />
        )}
      </FormDiv>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  fetchSpinner: getFetchSpinnerSelector(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppDispatch>,
): IDispatchProps => ({
  resetPassword: (payload: IRemindPasswordPayload): AppDispatch =>
    dispatch(resetPassword(payload)),
  resetUserPassword: (payload: IRemindPasswordPayload): AppDispatch =>
    dispatch(resetUserPassword(payload)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(ResetPasswordContainer)),
);
