/** @jsx jsx */
import { jsx } from '@emotion/core';
import { AppBar } from '@material-ui/core';
import { PureComponent, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { versionDigits } from 'utils/versionDigits';
import loginHeaderStyle from './LoginHeader.styled';

interface IProps {
  children?: ReactNode;
}

class LoginHeader extends PureComponent<IProps & WithTranslation> {
  render(): JSX.Element {
    const { t } = this.props;
    return (
      <AppBar position="relative">
        <div css={loginHeaderStyle.logoContainer}>
          <img css={loginHeaderStyle.img} src="/logo.svg" />
          <span css={loginHeaderStyle.title}>
            {t('common.title')}{' '}
            {t('common.version', {
              version: versionDigits(process.env.REACT_APP_VERSION),
            })}
          </span>
        </div>
        {this.props.children}
      </AppBar>
    );
  }
}

export default withTranslation()(LoginHeader);
