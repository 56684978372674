import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import {
  AuthenticationResult,
  RefreshTokenResult,
  UserDTO,
  XTMCustomerDTO,
  XTMTemplateDTO,
} from 'utils/restApplicationClient';
import { XTMConnectionParametersDTO } from 'utils/restApplicationClientTypeOverrides';

const authSelector = (state: AppState): boolean => state.user.auth;

const verifyFinishSelector = (state: AppState): boolean =>
  state.user.verifyFinished;

const userDataSelector = (
  state: AppState,
): AuthenticationResult & RefreshTokenResult => {
  return state.user.data;
};

const userErrorsSelector = (state: AppState): { [key: string]: string } =>
  state.user.errors;

const userSelector = (state: AppState): UserDTO | undefined => state.user.user;

const xtmAuthorizationIdSelector = ({
  user: { xtmAuthorizationId },
}: AppState): string | undefined => xtmAuthorizationId;

const xtmCustomersSelector = ({
  user: { customers },
}: AppState): XTMCustomerDTO[] | undefined => customers;

const xtmTemplatesSelector = ({
  user: { templates },
}: AppState): XTMTemplateDTO[] | undefined => templates;

const xtmConnectDataSelector = ({
  user: { connectData },
}: AppState): XTMConnectionParametersDTO | undefined => connectData;

const cmsConnectedSelector = ({
  user: { xtmCMSconnected },
}: AppState): boolean | undefined => xtmCMSconnected;

const sessionExpiredSelector = ({
  user: { sessionExpired },
}: AppState): boolean => sessionExpired;

export const getAuthSelector = createSelector(authSelector, (auth) => auth);

export const getVerifyFinishSelector = createSelector(
  verifyFinishSelector,
  (finished) => finished,
);

export const getUserDataSelector = createSelector(
  userDataSelector,
  (roles) => roles,
);

export const getUserErrorsSelector = createSelector(
  userErrorsSelector,
  (errors) => errors,
);

export const getUserSelector = createSelector(userSelector, (user) => user);

export const getXtmAuthorizationIdSelector = createSelector(
  xtmAuthorizationIdSelector,
  (authorizationId) => authorizationId,
);

export const getXtmCustomersSelector = createSelector(
  xtmCustomersSelector,
  (customers) => customers,
);

export const getXtmTemplatesSelector = createSelector(
  xtmTemplatesSelector,
  (templates) =>
    Array.isArray(templates) && templates.length === 0 ? undefined : templates,
);

export const getXtmConnectDataSelector = createSelector(
  xtmConnectDataSelector,
  (connectData) => connectData,
);

export const getCMSConnectedSelector = createSelector(
  cmsConnectedSelector,
  (cmsConnected) => cmsConnected,
);

export const getSessionExpiredSeletor = createSelector(
  sessionExpiredSelector,
  (sessionExpired) => sessionExpired,
);
