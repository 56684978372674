/** @jsx jsx */
// eslint-disable  @typescript-eslint/no-explicit-any

import { css, jsx } from '@emotion/core';
import { FormControl, SelectProps, Tooltip } from '@material-ui/core';
import { Component } from 'react';
import { FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  customInputStyles,
  SelectDiv,
  StyledSelect,
} from './CustomInput.style';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface IProps {
  label: string;
  error: string;
  touched?: boolean;
  inputProps: FieldInputProps<unknown, HTMLElement>;
  testId?: string;
  rows: Array<{ value: string; name: string }>;
  width?: string;
  selectProps?: SelectProps;
  // eslint-disable-next-line
  onChange?: (event: any) => void;
  tooltip?: boolean;
  tooltipText?: string;
}

export class CustomSelect extends Component<IProps & WithTranslation> {
  render(): JSX.Element {
    const {
      t,
      error,
      touched,
      testId,
      inputProps,
      rows,
      width,
      onChange,
      tooltip,
      tooltipText,
    } = this.props;

    const selectStyle = css`
      width: ${width ? width : 'auto'};
      margin-top: 8px;
      margin-bottom: 4px;
      background-color: white;
    `;

    return (
      <div css={customInputStyles.root}>
        <SelectDiv>
          <div css={customInputStyles.labelDiv}>
            {' '}
            <span css={customInputStyles.label}>{t(this.props.label)}</span>
            {tooltip && tooltipText ? (
              <Tooltip title={tooltipText}>
                <HelpOutlineIcon />
              </Tooltip>
            ) : null}
          </div>
          <FormControl margin="dense" css={customInputStyles.formControl}>
            <StyledSelect
              {...this.props.selectProps}
              css={[
                error && touched ? customInputStyles.outlineError : {},
                selectStyle,
              ]}
              variant="outlined"
              margin="dense"
              onChange={onChange}
              native={
                this.props.selectProps?.native
                  ? this.props.selectProps?.native
                  : true
              }
              inputProps={{
                ...inputProps,
                'data-testid': testId,
                placeholder: 'choose...',
              }}
            >
              {!this.props.inputProps.value && (
                <option value={undefined}></option>
              )}
              {rows.map((row, index) => {
                return (
                  <option value={row.value} key={index}>
                    {row.name}
                  </option>
                );
              })}
            </StyledSelect>
          </FormControl>
        </SelectDiv>

        {error && touched && (
          <span
            css={customInputStyles.textError}
            data-testid={`${testId}Error`}
          >
            {t(error)}
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(CustomSelect);
