import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const Title = styled.div`
  margin: 16px 0;
`;

export const addClientContainerStyles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
  `,
  heading: css`
    color: #0796eb;
    font-weight: 100;
  `,

  form: css`
    margin: 16px;
  `,
  btnContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
    margin: 0 auto;
    margin-top: 20px !important;
  `,
  checkbox: css`
    display: flex;
  `,
};
