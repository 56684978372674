import { css } from '@emotion/core';

export const tableEmptyStyles = {
  root: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white !important;
    padding: 20px;
    width: 100%;
  `,
  icon: css`
    font-size: 80px !important;
  `,
  text: css`
    color: grey;
    letter-spacing: 0.075em !important;
  `,
};
