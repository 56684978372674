import { createReducer } from '@reduxjs/toolkit';
import {
  clearClient,
  clearClientErrors,
  createClientError,
  getAllClientsSuccess,
  getAllLanguagesSuccess,
  getClientByIdSuccess,
  getClientsPageableSuccess,
} from './client.actions';
import { IClientState } from './client.interface';

const initState: IClientState = {
  clients: [],
  errors: {},
  clientsPageable: undefined,
  client: undefined,
  contentfulLanguages: [],
  xtmLanguages: [],
};

export const clientReducer = createReducer(initState, (builder) =>
  builder
    .addCase(getAllClientsSuccess, (state, action) => {
      state.clients = action.payload;
    })
    .addCase(createClientError, (state, action) => {
      state.errors.clientName = action.payload;
    })
    .addCase(getClientsPageableSuccess, (state, action) => {
      state.clientsPageable = action.payload;
    })
    .addCase(clearClient, (state) => {
      state.client = undefined;
    })
    .addCase(getClientByIdSuccess, (state, action) => {
      state.client = action.payload;
    })
    .addCase(clearClientErrors, (state) => {
      state.errors = {};
    })
    .addCase(
      getAllLanguagesSuccess,
      (state, { payload: { allContentfulLanguages, allXtmLanguages } }) => {
        state.contentfulLanguages = allContentfulLanguages;
        state.xtmLanguages = allXtmLanguages;
      },
    ),
);
