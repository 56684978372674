export enum SettingsActionTypes {
  fetchSettings = '[SETTINGS] FETCH SETTINGS',
  fetchSettingsSuccess = '[SETTINGS] FETCH SETTINGS SUCCESS',
  fetchSettingsError = '[SETTINGS] FETCH SETTINGS ERROR',
  getSettings = '[SETTINGS] GET SETTINGS',
  getSettingsSuccess = '[SETTINGS] GET SETTINGS SUCCESS',
  getSettingsError = '[SETTINGS] GET SETTINGS ERROR',
  getAllSettings = '[SETTINGS] GET ALL SETTINGS',
  getAllSettingsSuccess = '[SETTINGS] GET ALL SETTINGS SUCCESS',
  getAllSettingsError = '[SETTINGS] GET ALL SETTINGS ERROR',
  InitializeContentful = '[SETTINGS] INITIALIZE CONNECTION TO CONTENTFUL',
  InitializeContentfulSuccess = '[SETTINGS] INITIALIZE CONNECTION TO CONTENTFUL SUCCESS',
  InitializeContentfulError = '[SETTINGS] INITIALIZE CONNECTION TO CONTENTFUL ERROR',
  InitializationCheck = '[SETTINGS] INITIALIZATION SETTINGS CHECK',
  InitializationCheckSuccess = '[SETTINGS] INITIALIZATION SETTINGS CHECK SUCCESS',
  InitializationCheckError = '[SETTINGS] INITIALIZATION SETTINGS CHECK ERROR',
  addSpace = '[SETTINGS] ADD SPACE',
  setClientID = '[SETTINGS] SET CLIENT ID',
  deleteOrganizationSettings = '[SETTINGS] DELETE ORGANIZATION SETTINGS',
  deleteOrganizationSettingsSuccess = '[SETTINGS] DELETE ORGANIZATION SETTINGS SUCCESS',
  deleteOrganizationSettingsError = '[SETTINGS] DELETE ORGANIZATION SETTINGS ERROR',
  updateSettings = '[SETTINGS] UPDATE SETTINGS',
  updateSettingsError = '[SETTINGS] UPDATE SETTINGS ERROR',
  updateSettingsSuccess = '[SETTINGS] UPDATE SETTINGS SUCCESS',
  setCurrentOrganizationSettings = '[SETTINGS] SET CURRENT ORGANIZATION SETTINGS',
  changeCredentials = `[SETTINGS] CHANGE CREDENTIALS`,
  changeCredentialsSuccess = `[SETTINGS] CHANGE CREDENTIALS SUCCESS`,
  changeCredentialsError = `[SETTINGS] CHANGE CREDENTIALS ERROR`,
}
