/** @jsx jsx */
import { jsx } from '@emotion/core';
import { CircularProgress } from '@material-ui/core';
import { tableToolbarStyles } from 'components/TableToolbar/TableToolbar.style';
import { FormApi } from 'final-form';
import { ChangeEvent, Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import {
  getCustomerSpinnerSelector,
  getTemplateSpinnerSelector,
} from 'store/spinner/spinner.selectors';
import {
  clearXtmTemplates,
  xtmGetCustomers,
  xtmGetCustomersById,
  xtmGetTemplates,
  xtmGetTemplatesById,
} from 'store/user/user.actions';
import {
  getXtmCustomersSelector,
  getXtmTemplatesSelector,
} from 'store/user/user.selectors';
import {
  composeValidators,
  isAllowedPrefix,
  isMaxLength,
  isNumber,
  required,
} from 'utils/customValidators';
import { isObjectEqual } from 'utils/objectEquality';
import {
  UserDTO,
  XTMConnectDTO,
  XTMCustomerDTO,
  XTMTemplateDTO,
} from 'utils/restApplicationClient';
import { CreateUserDTO } from 'utils/restApplicationClientTypeOverrides';
import CustomFieldRow from '../Input/CustomFieldRow';
import CustomSelect from '../Input/CustomSelect';

interface IProps {
  native?: boolean;
  authorizationId?: string;
  user?: UserDTO;
  form: FormApi<CreateUserDTO>;
}

interface IDispatchProps {
  getCustomers: (payload: XTMConnectDTO) => AppDispatch;
  getTemplates: (payload: {
    xtmAuthId: string;
    xtmCustomerId: string;
  }) => AppDispatch;
  getCustomersById: (payload: string) => AppDispatch;
  getTemplatesById: (payload: {
    userId: string;
    queryParams: { xtmCustomerId: string };
  }) => AppDispatch;
  clearTemplates: () => AppDispatch;
}

interface IStateProps {
  customers?: XTMCustomerDTO[];
  templates?: XTMTemplateDTO[];
  customerSpinner: boolean;
  templateSpinner: boolean;
}

type PropType = IProps & IDispatchProps & IStateProps;

export class ConnectedFields extends Component<PropType> {
  componentDidMount(): void {
    const { authorizationId, getCustomers, user, getTemplatesById } =
      this.props;
    if (user) {
      getTemplatesById({
        userId: user.id,
        queryParams: {
          xtmCustomerId: user.xtmProperties.xtmCustomerId.toString(),
        },
      });
    } else if (authorizationId) {
      getCustomers({ xtmAuthorizationId: authorizationId });
    }
  }

  componentDidUpdate(previousProps: PropType): void {
    const { authorizationId } = this.props;
    if (
      this.props.customers &&
      previousProps.customers &&
      this.shouldUpdate(previousProps.customers, this.props.customers) &&
      authorizationId
    ) {
      this.props.form.change('xtmCustomerId', undefined);
      this.props.form.change('xtmTemplateId', undefined);
      this.props.clearTemplates();
    }
  }

  shouldUpdate(
    previousCustomers: XTMCustomerDTO[],
    customers: XTMCustomerDTO[],
  ): boolean {
    if (previousCustomers.length !== customers.length) {
      return true;
    }
    return !customers.reduce(
      (previous: boolean, current: XTMCustomerDTO, index) => {
        return (
          isObjectEqual(
            current as unknown as Record<string, unknown>,
            previousCustomers[index] as unknown as Record<string, unknown>,
          ) && previous
        );
      },
      true,
    );
  }

  mapCustomersToRows(
    customers?: XTMCustomerDTO[],
  ): Array<{ value: string; name: string }> {
    if (!customers) {
      return [];
    }
    return customers.map((customer) => {
      return { value: customer.xtmCustomerId.toString(), name: customer.name };
    });
  }

  mapTemplatesToRows(
    templates?: XTMTemplateDTO[],
  ): Array<{ value: string; name: string }> {
    if (!templates) {
      return [];
    }
    return templates.map((template) => {
      return { value: template.xtmTemplateId.toString(), name: template.name };
    });
  }

  handleCustomerChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ): void => {
    const { authorizationId, getTemplates, getTemplatesById, user } =
      this.props;
    if (user && user.id) {
      getTemplatesById({
        userId: user.id,
        queryParams: { xtmCustomerId: event.target.value as string },
      });
    } else if (authorizationId) {
      getTemplates({
        xtmAuthId: authorizationId,
        xtmCustomerId: event.target.value as string,
      });
    }
  };

  selectSpinner = (): JSX.Element => {
    return (
      <CircularProgress
        data-testid="connectedFieldsSpinner"
        color="secondary"
        size={20}
        css={tableToolbarStyles.endAdornmentSpinner}
      />
    );
  };

  render(): JSX.Element {
    const {
      native,
      customers,
      templates,
      customerSpinner,
      templateSpinner,
      user,
    } = this.props;
    return (
      <Fragment>
        <Field
          name="xtmCustomerId"
          validate={required}
          initialValue={
            user &&
            customers &&
            user.xtmProperties &&
            user.xtmProperties.xtmCustomerId
              ? user.xtmProperties.xtmCustomerId.toString()
              : undefined
          }
        >
          {({ input, meta }): JSX.Element => (
            <CustomSelect
              label="users.xtm.defaultCustomer"
              error={meta.error}
              touched={meta.touched}
              inputProps={{ ...input }}
              testId="defaultCustomer"
              rows={this.mapCustomersToRows(customers)}
              selectProps={{
                native,
                disabled: !customers,
                IconComponent: customerSpinner ? this.selectSpinner : undefined,
              }}
              onChange={this.handleCustomerChange}
            />
          )}
        </Field>
        <Field
          name="xtmTemplateId"
          validate={required}
          initialValue={
            user &&
            templates &&
            user.xtmProperties &&
            user.xtmProperties.xtmTemplateId
              ? user.xtmProperties.xtmTemplateId.toString()
              : undefined
          }
        >
          {({ input, meta }): JSX.Element => (
            <CustomSelect
              label="users.xtm.defaultTemplate"
              error={meta.error}
              touched={meta.touched}
              inputProps={{ ...input }}
              testId="defaultTemplate"
              rows={this.mapTemplatesToRows(templates)}
              selectProps={{
                native,
                disabled: !templates,
                IconComponent: templateSpinner ? this.selectSpinner : undefined,
              }}
            />
          )}
        </Field>
        <Field
          name="xtmProjectNamePrefix"
          validate={composeValidators([required, isAllowedPrefix])}
          initialValue={
            user &&
            user.xtmProperties &&
            user.xtmProperties.xtmProjectNamePrefix
          }
        >
          {({ input, meta }): JSX.Element => (
            <CustomFieldRow
              label="users.xtm.projectNamePrefix"
              error={meta.error}
              touched={meta.touched}
              inputProps={input}
              testId="prefixNameInput"
            />
          )}
        </Field>
        <Field
          name="removeXTMFilesAfterDays"
          validate={composeValidators([required, isNumber, isMaxLength])}
          initialValue={
            user &&
            user.xtmProperties &&
            user.xtmProperties.removeXTMFilesAfterDays
              ? user.xtmProperties.removeXTMFilesAfterDays.toString()
              : undefined
          }
        >
          {({ input, meta }): JSX.Element => (
            <CustomFieldRow
              label="users.numberOfDaysCMS"
              error={meta.error}
              touched={meta.touched}
              inputProps={input}
              testId="removeXTMInput"
            />
          )}
        </Field>
        <Field
          name="removeCancelledJobsAfterDays"
          validate={composeValidators([required, isNumber, isMaxLength])}
          initialValue={
            user &&
            user.xtmProperties &&
            user.xtmProperties.removeCancelledJobsAfterDays
              ? user.xtmProperties.removeCancelledJobsAfterDays.toString()
              : undefined
          }
        >
          {({ input, meta }): JSX.Element => (
            <CustomFieldRow
              label="users.numberOfDaysXTM"
              error={meta.error}
              touched={meta.touched}
              inputProps={input}
              testId="removeInput"
            />
          )}
        </Field>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  customers: getXtmCustomersSelector(state),
  templates: getXtmTemplatesSelector(state),
  customerSpinner: getCustomerSpinnerSelector(state),
  templateSpinner: getTemplateSpinnerSelector(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppDispatch>,
): IDispatchProps => ({
  getCustomers: (payload: XTMConnectDTO): AppDispatch =>
    dispatch(xtmGetCustomers(payload)),
  getTemplates: (payload: {
    xtmAuthId: string;
    xtmCustomerId: string;
  }): AppDispatch => dispatch(xtmGetTemplates(payload)),
  getCustomersById: (payload: string): AppDispatch =>
    dispatch(xtmGetCustomersById(payload)),
  getTemplatesById: (payload: {
    userId: string;
    queryParams: { xtmCustomerId: string };
  }): AppDispatch => dispatch(xtmGetTemplatesById(payload)),
  clearTemplates: (): AppDispatch => dispatch(clearXtmTemplates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedFields);
