import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';

export const CustomLanguageMappingFieldContainer = styled.div`
  margin: 12px 12px 8px 0;
  width: 100%;
`;

export const CustomLanguageMappingContainer = styled.fieldset`
  margin: 20px -16px;
  display: flex;
  flex-direction: column;
  padding: 16px 13px 16px 15px;
  border: 1px solid #6f8294;
`;

export const CustomLanguageMappingLegend = styled.legend`
  text-align: initial;
  font-weight: 700;
  font-size: 14px;
  color: #333;
  padding: 0 4px;
  margin-left: -5px;
`;

export const CustomLanguageMappingButton = styled(IconButton)`
  align-self: flex-start;
  padding: 0 !important;
  color: #38af49 !important;
`;

export const CustomLanguageMappingRemoveButton = styled(IconButton)`
  margin: 36px -8px 0 -8px !important;
`;
