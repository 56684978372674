/**
 * Returns whether objects are equal (shallow compare)
 *
 * @param objectA - Key - Value object
 *
 * @param objectB - Key - Value object
 *
 * @returns {boolean} Whether objects are equal
 */
export function isObjectEqual(
  objectA: Record<string, unknown>,
  objectB: Record<string, unknown>,
): boolean {
  const objectAProperties = Object.getOwnPropertyNames(objectA);
  const objectBProperties = Object.getOwnPropertyNames(objectB);

  if (objectAProperties.length !== objectBProperties.length) {
    return false;
  }

  return objectAProperties.reduce(
    (previousValue: boolean, currentValue: string) =>
      objectA[currentValue] === objectB[currentValue] && previousValue,
    true,
  );
}
