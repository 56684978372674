import { createReducer } from '@reduxjs/toolkit';
import {
  setConnectDataSpinner,
  setConnectToCMSSpinner,
  setConnectToXtmSpinner,
  setCustomerSpinner,
  setFetchSpinner,
  setSettingsSpinner,
  setTemplateSpinner,
} from './spinner.actions';
import { ISpinnerState } from './spinner.interface';

const initState: ISpinnerState = {
  fetchSpinner: false,
  templateSpinner: false,
  customerSpinner: false,
  connectDataSpinner: false,
  connectToXtmSpinner: false,
  connectToCMSSpinner: false,
  settingsSpinner: false,
};

export const spinnerReducer = createReducer(initState, (builder) =>
  builder
    .addCase(setFetchSpinner, (state, action) => {
      state.fetchSpinner = action.payload;
    })
    .addCase(setTemplateSpinner, (state, action) => {
      state.templateSpinner = action.payload;
    })
    .addCase(setCustomerSpinner, (state, action) => {
      state.customerSpinner = action.payload;
    })
    .addCase(setConnectDataSpinner, (state, action) => {
      state.connectDataSpinner = action.payload;
    })
    .addCase(setConnectToXtmSpinner, (state, action) => {
      state.connectToXtmSpinner = action.payload;
    })
    .addCase(setConnectToCMSSpinner, (state, action) => {
      state.connectToCMSSpinner = action.payload;
    })
    .addCase(setSettingsSpinner, (state, action) => {
      state.settingsSpinner = action.payload;
    }),
);
