import CustomTableContainer from 'components/Table/CustomTable';
import TableToolbar from 'components/TableToolbar/TableToolbar';
import { UserRouteParameterEnum } from 'enums/userRouteParameter';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppState } from 'store';
import { usersPageable } from 'store/user/user.actions';
import { Page, UserDTO } from 'utils/restApplicationClient';
import { getUsersPageableSelector } from '../../store/user/users.selectors';

interface IStateProps {
  users?: Page<UserDTO>;
}

const UserTable = CustomTableContainer<UserDTO>();

class UsersContainer extends Component<IStateProps & RouteComponentProps> {
  headers = [
    { label: 'common.id', id: 'id' },
    { label: 'common.userEmail', id: 'email' },
    { label: 'common.client', id: 'client.clientName' },
    { label: 'common.status', id: 'status' },
    { label: 'common.creationDate', id: 'createdAt' },
    { label: 'common.modificationDate', id: 'modifiedAt' },
  ];
  onEdit = (row: UserDTO): void => {
    this.props.history.push(`user/${UserRouteParameterEnum.edit}/${row.id}`);
  };

  onDuplicate = (row: UserDTO): void => {
    this.props.history.push(
      `user/${UserRouteParameterEnum.duplicate}/${row.id}`,
    );
  };

  onAdd = (): void => {
    this.props.history.push('user/add');
  };
  render(): JSX.Element {
    const { users } = this.props;
    return (
      <div>
        <TableToolbar buttonLabelKey="users.add" onAdd={this.onAdd} />
        <UserTable
          headers={this.headers}
          onEdit={this.onEdit}
          paginationAction={usersPageable}
          pageable={users}
          search={''}
          onDuplicate={this.onDuplicate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  users: getUsersPageableSelector(state),
});

export default connect(mapStateToProps)(withRouter(UsersContainer));
