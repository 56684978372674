import CustomTableContainer from 'components/Table/CustomTable';
import TableToolbar from 'components/TableToolbar/TableToolbar';
import { Roles } from 'enums/roles';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { getAllSettings } from 'store/settings/settings.actions';
import { getAllSettingsSelector } from 'store/settings/settings.selectors';
import { getUserDataSelector } from 'store/user/user.selectors';
import { checkRoles } from 'utils/checkRoles';
import { useTypedSelector } from 'utils/hooks/TypedSelector';
import { PageableSettingsDTO } from 'utils/restApplicationClient';

const ClientTable = CustomTableContainer<PageableSettingsDTO>();

const SettingsClientList = (): JSX.Element => {
  const headers = [
    { label: 'common.clientName', id: 'clientName', orderId: 'client_name' },
    {
      label: 'common.settingsInitialized',
      id: 'settingsInitialized',
      orderId: 'is_initialized',
    },
    { label: 'common.creationDate', id: 'createdAt', orderId: 'created_at' },
    {
      label: 'common.modificationDate',
      id: 'modifiedAt',
      orderId: 'modified_at',
    },
  ];

  const clients = useTypedSelector(getAllSettingsSelector);
  const history = useHistory();
  const currentUser = useTypedSelector(getUserDataSelector);
  const isAdmin = checkRoles([Roles.ADMIN_XTM], currentUser.roles as Roles[]);

  const onEdit = (row: PageableSettingsDTO): void => {
    history.push(`/settings/connect/${row.id}`);
  };

  useEffect(() => {
    if (!isAdmin) {
      history.push(`/settings/connect`);
    }
  }, []);
  return isAdmin ? (
    <>
      <TableToolbar />
      <ClientTable
        headers={headers}
        onEdit={onEdit}
        paginationAction={getAllSettings}
        pageable={clients}
        search={''}
      />
    </>
  ) : (
    <></>
  );
};

export default SettingsClientList;
