import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { tokenStorageKey } from 'utils/axiosInstance';
import { getAllEnvironment } from 'utils/envHandler';
import { HeaderButton } from '../LoginHeader.styled';

interface IProps {
  onClick: (filename: string) => () => void;
}

export const ReleaseNotes: FC<IProps> = ({ onClick }) => {
  const { REACT_APP_CHANGELOG_URL } = getAllEnvironment();
  const { t } = useTranslation();

  if (localStorage.getItem(tokenStorageKey) && REACT_APP_CHANGELOG_URL) {
    return (
      <HeaderButton
        disableTouchRipple
        onClick={onClick(REACT_APP_CHANGELOG_URL)}
      >
        <svg
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5 0.5H1.5C0.65625 0.5 0 1.17969 0 2V8.75C0 9.59375 0.65625 10.25 1.5 10.25H3.75V12.2188C3.75 12.4531 4.00781 12.5938 4.19531 12.4531L7.125 10.25H10.5C11.3203 10.25 12 9.59375 12 8.75V2C12 1.17969 11.3203 0.5 10.5 0.5ZM6.75 6.6875C6.75 6.80469 6.65625 6.875 6.5625 6.875H3.1875C3.07031 6.875 3 6.80469 3 6.6875V6.3125C3 6.21875 3.07031 6.125 3.1875 6.125H6.5625C6.65625 6.125 6.75 6.21875 6.75 6.3125V6.6875ZM9 4.4375C9 4.55469 8.90625 4.625 8.8125 4.625H3.1875C3.07031 4.625 3 4.55469 3 4.4375V4.0625C3 3.96875 3.07031 3.875 3.1875 3.875H8.8125C8.90625 3.875 9 3.96875 9 4.0625V4.4375Z"
            fill="white"
          />
        </svg>
        {t('common.releaseNotes')}
      </HeaderButton>
    );
  }

  return null;
};
