import AddAdministratorContainer from 'containers/AddAdministrator/AddAdministratorContainer';
import AddClientContainer from 'containers/AddClientContainer/AddClientContainer';
import AddUserContainer from 'containers/AddUser/AddUserContainer';
import AdministratorsContainer from 'containers/Administrators/AdministratorsContainer';
import ClientsContainer from 'containers/Clients/ClientsContainer';
import SettingsContainer from 'containers/Settings/SettingsContainer';
import SettingsClientList from 'containers/SettingsClientList/SettingsClientListContainer';
import SettingViewContainer from 'containers/SettingsView/SettingsViewContainer';
import UsersContainer from 'containers/Users/UsersContainer';
import { Roles } from 'enums/roles';
import { IRoute } from 'types/route';

export const mainRoutes: IRoute[] = [
  {
    pathname: '/clients',
    component: ClientsContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
    navbar: true,
    routeTextKey: 'common.clients',
  },
  {
    pathname: '/administrators',
    component: AdministratorsContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
    navbar: true,
    routeTextKey: 'common.administrators',
  },
  {
    pathname: '/users',
    component: UsersContainer,
    exact: true,
    roles: [Roles.ADMIN_CLIENT, Roles.ADMIN_XTM],
    navbar: true,
    routeTextKey: 'common.users',
  },
  {
    pathname: '/client/add',
    component: AddClientContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
  },
  {
    pathname: '/client/edit/:id',
    component: AddClientContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
  },
  {
    pathname: '/administrator/add',
    component: AddAdministratorContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
  },
  {
    pathname: '/administrator/edit/:id',
    component: AddAdministratorContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
  },
  {
    pathname: '/user/add',
    component: AddUserContainer,
    exact: true,
    roles: [Roles.ADMIN_CLIENT, Roles.ADMIN_XTM],
  },
  {
    pathname: '/user/:type/:id',
    component: AddUserContainer,
    exact: true,
    roles: [Roles.ADMIN_CLIENT, Roles.ADMIN_XTM],
  },
  {
    pathname: '/settings/connect',
    component: SettingsContainer,
    exact: true,
    roles: [Roles.ADMIN_CLIENT, Roles.ADMIN_XTM],
    routeTextKey: 'common.settings',
  },
  {
    pathname: '/settings/reconnect',
    component: SettingsContainer,
    exact: true,
    roles: [Roles.ADMIN_CLIENT, Roles.ADMIN_XTM],
    routeTextKey: 'common.settings',
  },
  {
    pathname: '/settings/connect/:clientId',
    component: SettingViewContainer,
    exact: true,
    roles: [Roles.ADMIN_CLIENT, Roles.ADMIN_XTM],
    routeTextKey: 'common.settings',
  },
  {
    pathname: '/settings',
    component: SettingsClientList,
    exact: true,
    roles: [Roles.ADMIN_XTM, Roles.ADMIN_CLIENT],
    navbar: true,
    routeTextKey: 'common.settings',
  },
];
