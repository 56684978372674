import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import {
  Page,
  PageableSettingsDTO,
  SettingsDTO,
} from 'utils/restApplicationClient';
import { IConfigState } from './settings.interface';

const SettingsSelector = (state: AppState): SettingsDTO[] => {
  return state.settings.SettingsDTO;
};

const AllSettingsSelector = (state: AppState): Page<PageableSettingsDTO> => {
  return state.settings.allSettings;
};

const AddSpaceSelector = (state: AppState): boolean => {
  return state.settings.addSpace;
};

const SuccessesSelector = (state: AppState): boolean => {
  return state.settings.successes;
};

const InitializationCheckSelector = (state: AppState): boolean => {
  return state.settings.initializationCheck;
};

const selectSelf = (state: AppState): IConfigState => {
  return state.settings;
};

export const getSettingsSelector = createSelector(
  SettingsSelector,
  (SettingsDTO) => SettingsDTO,
);

export const getAllSettingsSelector = createSelector(
  AllSettingsSelector,
  (allSettings) => allSettings,
);

export const setAddSpaceSelector = createSelector(
  AddSpaceSelector,
  (addSettings) => addSettings,
);
export const setClientIdSelector = createSelector(
  selectSelf,
  (state) => state.clientId,
);

export const getSuccessesSelector = createSelector(
  SuccessesSelector,
  (success) => success,
);

export const isInitializedSelector = createSelector(
  InitializationCheckSelector,
  (initializationCheck) => initializationCheck,
);

export const getSettingsErrorsSelector = (
  state: AppState,
): { [key: string]: string | undefined } => state.settings.errors;

// eslint-disable-next-line id-length
export const getCurrentOrganizationSettingsSelector = createSelector(
  selectSelf,
  (state) => state.selectedOrganizationSettings,
);
