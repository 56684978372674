import { css } from '@emotion/core';

export const resetPasswordContainerStyles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    max-width: 400px;
    align-self: center;
  `,
  button: css`
    margin-top: 20px !important;
    width: 75%;
  `,
  text: css`
    text-align: start;
    margin: 20px 10px;
  `,
  form: css`
    margin: 16px;
  `,
};
