import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  InputBase,
  Link,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';

export interface IDisabledProps {
  disabled?: number;
  spinner?: number;
}
export interface IPaperProps {
  width: number;
}

export interface IInputProps {
  error: number;
  opened: number;
  hovered: number;
  height?: number;
  width?: number;
}

export const DraggableSelectChip = styled(Chip)`
  margin: 8px 2px;
`;

export const DraggableSelectEllipsisContainer = styled(Box)`
  align-items: flex-start;
  opacity: ${({ disabled }: IDisabledProps) => (disabled ? `0.5` : '1')};
  display: ${({ spinner }: IDisabledProps) => (spinner ? `none` : 'flex')};
`;

export const DraggableSelectEllipsis = styled.span`
  margin-top: 3px !important;
`;

export const DraggableSelectArrowContainer = styled(Box)`
  align-items: flex-start;
  opacity: ${({ disabled }: IDisabledProps) => (disabled ? `0.5` : '1')};
  display: ${({ spinner }: IDisabledProps) => (spinner ? `none` : 'flex')};
`;

export const DraggableSelectButtonTypography = styled(Typography)`
  display: flex;
  justify-content: space-between;
  background: #e0e0e0;
  margin: 3px;
  border-radius: 20px;
  min-width: 50px;
  padding: 0 5px;
`;

export const DraggableSelectIconButton = styled(IconButton)`
  padding: 0 !important;
  color: #fff;
  margin-top: 3px !important;
`;

export const DraggableSelectOptionCheckbox = styled(Checkbox)`
  padding: 0 !important;
`;

export const DraggableSelectInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 32px;
`;

export const DraggableSelectInput = styled(InputBase)`
  width: 90%;
  margin: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: #fff;
  .MuiInputBase-input {
    padding: 5px;
    min-height: 30px;
  }
`;

export const DraggableSelectChipContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const DraggableSelectDataContainer = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-color: ${({ error }: IInputProps) =>
    error ? `#f44336` : 'rgba(0, 0, 0, 0.23)'} !important;
  display: flex;
  margin: 5px 0;
  border-radius: ${({ opened }: IInputProps) =>
    opened ? `4px 4px 0 0` : '4px'} !important;
  background: #fff;
  padding: 0 6px;
  justify-content: space-between !important;
  min-height: 32px;
  max-height: 32px;
  box-sizing: border-box;
  overflow: ${({ hovered, opened }: IInputProps) =>
    hovered || opened ? `auto` : 'hidden'};
  max-height: ${({ hovered, height }: IInputProps) =>
    hovered ? `${height}px` : '32px'};
  overflow: hidden;
  width: ${({ width }: IInputProps) => (width ? `${width}px` : '100%')};
  transition: ${({ opened }: IInputProps) =>
    opened ? `unset` : 'max-height 0.2s ease'};

  &:hover {
    cursor: pointer;
    border-color: ${({ error }: IInputProps) =>
      error ? `#f44336` : '#536171'} !important;
  }

  .MuiChip-root {
    margin: 4px 2px !important;
  }

  .MuiChip-deleteIconSmall {
    width: 22px;
    height: 22px;
    color: white;
  }
`;

export const DraggableSelectSpinnerContainer = styled(Box)`
  align-items: center;
  margin-right: 5px;
  display: ${({ spinner }: IDisabledProps) => (spinner ? `flex` : 'none')};
`;

export const DraggableSelectDefaultChip = styled.span`
  color: rgba(0, 0, 0, 0.35);
  padding-left: 5px;
`;

export const DraggableSelectActionButton = styled(Link)`
  color: #00a4e4 !important;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const DraggableSelectButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
`;

export const DraggableSelectAutocompletePaper = styled(Paper)`
  margin-left: -1px !important;
  margin-top: 0 !important;
  width: ${({ width }: IPaperProps) => (width ? `${width}px` : 'initial')};
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: unset !important;
  box-shadow: unset !important;

  .MuiAutocomplete-listbox {
    max-height: 25vh !important;
  }
`;

export const DraggableSelectPopper = styled(Popper)`
  width: ${({ width }: IPaperProps) => (width ? `${width}px` : 'initial')};
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-top: 0;
`;

export const DraggableSelectSelectClasses = {
  autocomplete: css`
    position: relative;
    background-color: #fff;
    box-sizing: border-box;
  `,
};
