import { createAction } from '@reduxjs/toolkit';
import {
  AuthenticationResult,
  Page,
  RefreshTokenResult,
  UserDTO,
  UserOverviewDTO,
  XTMConnectDTO,
  XTMCustomerDTO,
  XTMTemplateDTO,
} from 'utils/restApplicationClient';
import {
  AuthenticationRequest,
  ChangePasswordDTO,
  CreateUserDTO,
  XTMConnectionParametersDTO,
  XTMConnectRequest,
} from 'utils/restApplicationClientTypeOverrides';
import { IRemindPasswordPayload, UserActionTypes } from './user.actions.types';
import { IEditUser, IUsersPageable } from './user.interface';

export const verifyPassToken = createAction<string>(
  UserActionTypes.verifyPassToken,
);
export const verifyPassTokenSuccess = createAction(
  UserActionTypes.verifyPassTokenSuccess,
);
export const verifyPassTokenError = createAction(
  UserActionTypes.verifyPassTokenError,
);
export const changePassword = createAction<ChangePasswordDTO>(
  UserActionTypes.ChangePassword,
);
export const changePasswordSuccess = createAction(
  UserActionTypes.ChangePasswordSuccess,
);
export const changePasswordError = createAction(
  UserActionTypes.ChangePasswordError,
);
export const changeUserPassword = createAction<ChangePasswordDTO>(
  UserActionTypes.ChangeUserPassword,
);
export const changeUserPasswordSuccess = createAction(
  UserActionTypes.ChangeUserPasswordSuccess,
);
export const changeUserPasswordError = createAction(
  UserActionTypes.ChangeUserPasswordError,
);
export const loginUser = createAction<AuthenticationRequest>(
  UserActionTypes.Login,
);
export const loginUserSuccess = createAction<AuthenticationResult>(
  UserActionTypes.LoginSuccess,
);
export const loginUserError = createAction<
  { [key: string]: string | undefined } | undefined
>(UserActionTypes.LoginError);

export const logoutUser = createAction(UserActionTypes.Logout);
export const logoutUserSuccess = createAction(UserActionTypes.LogoutSuccess);
export const logoutUserError = createAction(UserActionTypes.LogoutError);

export const resetPassword = createAction<IRemindPasswordPayload>(
  UserActionTypes.ResetPassword,
);
export const resetPasswordSuccess = createAction(
  UserActionTypes.ResetPasswordSuccess,
);
export const resetPasswordError = createAction(
  UserActionTypes.ResetPasswordError,
);

export const resetUserPassword = createAction<IRemindPasswordPayload>(
  UserActionTypes.ResetUserPassword,
);
export const resetUserPasswordSuccess = createAction(
  UserActionTypes.ResetUserPasswordSuccess,
);
export const resetUserPasswordError = createAction(
  UserActionTypes.ResetUserPasswordError,
);

export const verifyToken = createAction(UserActionTypes.VerifyToken);
export const verifyTokenSuccess = createAction<{ ttl: number }>(
  UserActionTypes.VerifyTokenSuccess,
);
export const verifyTokenError = createAction(UserActionTypes.VerifyTokenError);
export const refreshTokenSuccess = createAction<RefreshTokenResult>(
  UserActionTypes.RefreshTokenSuccess,
);

export const getAllUsers = createAction(UserActionTypes.GetAllUsers);
export const getAllUsersError = createAction(UserActionTypes.GetAllUsersError);
export const getAllUsersSuccess = createAction<UserDTO[]>(
  UserActionTypes.GetAllUsersSuccess,
);
export const createUser = createAction<CreateUserDTO>(
  UserActionTypes.CreateUser,
);
export const createUserSuccess = createAction(
  UserActionTypes.CreateUserSuccess,
);
export const createUserError = createAction<
  { [key: string]: string | undefined } | undefined
>(UserActionTypes.CreateUserError);

export const usersPageable = createAction<IUsersPageable>(
  UserActionTypes.UsersPageable,
);
export const usersPageableSuccess = createAction<Page<UserOverviewDTO>>(
  UserActionTypes.UsersPageableSuccess,
);
export const usersPageableError = createAction(
  UserActionTypes.UsersPageableError,
);
export const editUser = createAction<IEditUser>(UserActionTypes.EditUser);
export const editUserSuccess = createAction(UserActionTypes.EditUserSuccess);
export const editUserError = createAction(UserActionTypes.EditUserError);

export const getUserById = createAction<string>(UserActionTypes.GetUserById);
export const getUserByIdSuccess = createAction<UserDTO>(
  UserActionTypes.GetUserByIdSuccess,
);
export const getUserByIdError = createAction(UserActionTypes.GetUserByIdError);

export const clearUser = createAction(UserActionTypes.ClearUser);

export const clearUserErrors = createAction(UserActionTypes.ClearUserErrors);

export const xtmConnect = createAction<XTMConnectRequest>(
  UserActionTypes.xtmConnect,
);

export const xtmConnectError = createAction(UserActionTypes.xtmConnectError);

export const xtmConnectSuccess = createAction<XTMConnectDTO>(
  UserActionTypes.xtmConnectSuccess,
);

export const xtmConnectUser = createAction<{
  userId: string;
  xtmParameters: XTMConnectRequest;
}>(UserActionTypes.xtmConnectUser);

export const xtmConnectUserError = createAction(
  UserActionTypes.xtmConnectUserError,
);

export const xtmConnectUserSuccess = createAction<XTMConnectDTO>(
  UserActionTypes.xtmConnectUserSuccess,
);

export const xtmGetCustomers = createAction<XTMConnectDTO>(
  UserActionTypes.xtmGetCustomers,
);

export const xtmGetCustomersError = createAction(
  UserActionTypes.xtmGetCustomersError,
);

export const xtmGetCustomersSuccess = createAction<XTMCustomerDTO[]>(
  UserActionTypes.xtmGetCustomersSuccess,
);

export const xtmGetTemplates = createAction<{
  xtmAuthId: string;
  xtmCustomerId: string;
}>(UserActionTypes.xtmGetTemplates);

export const xtmGetTemplatesError = createAction(
  UserActionTypes.xtmGetTemplatesError,
);

export const xtmGetTemplatesSuccess = createAction<XTMTemplateDTO[]>(
  UserActionTypes.xtmGetTemplatesSuccess,
);

export const xtmGetCustomersById = createAction<string>(
  UserActionTypes.xtmGetCustomersById,
);

export const xtmGetCustomersByIdError = createAction(
  UserActionTypes.xtmGetCustomersByIdError,
);

export const xtmGetCustomersByIdSuccess = createAction<XTMCustomerDTO[]>(
  UserActionTypes.xtmGetCustomersByIdSuccess,
);

export const xtmGetTemplatesById = createAction<{
  userId: string;
  queryParams: { xtmCustomerId: string };
}>(UserActionTypes.xtmGetTemplatesById);

export const xtmGetTemplatesByIdError = createAction(
  UserActionTypes.xtmGetTemplatesByIdError,
);

export const xtmGetTemplatesByIdSuccess = createAction<XTMTemplateDTO[]>(
  UserActionTypes.xtmGetTemplatesByIdSucess,
);

export const xtmGetConnectData = createAction<string>(
  UserActionTypes.xtmGetConnectData,
);

export const xtmGetConnectDataError = createAction(
  UserActionTypes.xtmGetConnectDataError,
);

export const xtmGetConnectDataSuccess =
  createAction<XTMConnectionParametersDTO>(
    UserActionTypes.xtmGetConnectDataSuccess,
  );

export const xtmConnectToCMS = createAction<string>(
  UserActionTypes.xtmConnectToCMS,
);

export const xtmConnectToCMSError = createAction(
  UserActionTypes.xtmConnectToCMSError,
);

export const xtmConnectToCMSSuccess = createAction<string>(
  UserActionTypes.xtmConnectToCMSSuccess,
);

export const clearXtmTemplates = createAction(UserActionTypes.clearTemplates);

export const sessionExpired = createAction<boolean>(
  UserActionTypes.sessionExpired,
);

export const showSessionExpired = createAction(
  UserActionTypes.showSessionExpired,
);

export const hideSessionExpired = createAction(
  UserActionTypes.hideSessionExpired,
);
