/**
 *  Return string value either from .env file or window._env_ if defined
 *
 * @param key - Env variable key
 *
 */
export const getEnvironment = (key: string): string | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const env = (window as any)._env_;
  return env ? env[key] : process.env[key];
};

/**
 *  Return all evnironmental variables either from .env file or window._env_ if defined
 */
export const getAllEnvironment = (): { [key: string]: string } => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any)._env_ || process.env;
};
