/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Fragment, PureComponent, ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
  center?: boolean;
  width?: number;
}

class FormDiv extends PureComponent<IProps> {
  StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${this.props.width ? this.props.width : '700'}px;
    align-self: center;
    margin-bottom: 60px;
    ${this.props.center ? 'margin: auto;' : ''}
    & form {
      width: 100%;
    }
  `;

  render(): JSX.Element {
    const { StyledDiv } = this;
    return (
      <Fragment>
        <StyledDiv>{this.props.children}</StyledDiv>
      </Fragment>
    );
  }
}

export default FormDiv;
