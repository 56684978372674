/** @jsx jsx */
import { Global, jsx } from '@emotion/core';
import { ThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { mainStyles } from 'main.style';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { theme } from 'theme';
import { initializeApi } from 'utils/axiosInstance';
import { checkVersion } from 'utils/checkVersion';
import { initTranslation } from 'utils/initTranslation';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

Sentry.init({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dsn: (window as any)._env_?.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
});

async function render(): Promise<void> {
  await checkVersion();

  initTranslation();

  initializeApi();

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Global styles={mainStyles} />
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

document.addEventListener('DOMContentLoaded', render);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
