import styled from '@emotion/styled';
import { Box, Card } from '@material-ui/core';

export const CustomSettingsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

export const RightElement = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const CentredContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomConnectionCard = styled(Card)`
  min-width: 500px;
`;
export const CustomTitleCard = styled.div`
  background-color: #eff3f7;
  padding: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Tittle = styled.div`
  width: 90%;
`;
