import { createAction } from '@reduxjs/toolkit';
import { IEditAdministrator, IPaginationOptions } from 'types/api';
import { AdministratorDTO, Page } from 'utils/restApplicationClient';
import { CreateAdministratorDTO } from 'utils/restApplicationClientTypeOverrides';
import { AdministratorActionTypes } from './administrator.actions.types';

export const createAdministrator = createAction<CreateAdministratorDTO>(
  AdministratorActionTypes.CreateAdministrator,
);
export const createAdministratorError = createAction<string | undefined>(
  AdministratorActionTypes.CreateAdministratorError,
);
export const createAdministratorSuccess = createAction(
  AdministratorActionTypes.CreateAdministratorSuccess,
);

export const clearAdministratorErrors = createAction(
  AdministratorActionTypes.ClearAdministratorErrors,
);

export const getAdministratorsPageable = createAction<IPaginationOptions>(
  AdministratorActionTypes.GetAdministatorsPageable,
);
export const getAdministratorsPageableSuccess = createAction<
  Page<AdministratorDTO>
>(AdministratorActionTypes.GetAdministratorsPageableSuccess);
export const getAdministratorsPageableError = createAction(
  AdministratorActionTypes.GetAdministratorsPageableError,
);

export const getAdministratorById = createAction<string>(
  AdministratorActionTypes.GetAdministratorById,
);
export const getAdministratorByIdSuccess = createAction<AdministratorDTO>(
  AdministratorActionTypes.GetAdministratorByIdSuccess,
);
export const getAdministratorByIdError = createAction(
  AdministratorActionTypes.GetAdministratorByIdError,
);

export const clearAdministrator = createAction(
  AdministratorActionTypes.ClearAdministrator,
);

export const editAdministrator = createAction<IEditAdministrator>(
  AdministratorActionTypes.EditAdministrator,
);

export const editAdministratorSuccess = createAction(
  AdministratorActionTypes.EditAdministratorSuccess,
);

export const editAdministratorError = createAction(
  AdministratorActionTypes.EditAdministratorError,
);
