import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import store from 'store';
import {
  setPaginationAction,
  setPaginationOptions,
  setPaginationSearch,
} from './table.actions';

const getRowsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(
      setPaginationOptions.type,
      setPaginationSearch.type,
      setPaginationAction.type,
    ),
    switchMap((action) => {
      const {
        dispatchAction,
        paginationOptions: { page, size, order, orderBy, search },
      } = store.getState().table;
      if (dispatchAction) {
        if (action.type !== setPaginationSearch.type) {
          store.dispatch(
            dispatchAction({
              page,
              size,
              search,
              sort: `${orderBy},${order}`,
            }),
          );
        } else {
          store.dispatch(
            setPaginationOptions({
              page: 0,
              size,
              search,
              orderBy,
              order,
            }),
          );
        }
      }
      return EMPTY;
    }),
  );

export const tableEpics = combineEpics(getRowsEpic);
