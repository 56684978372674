export const EMAIL_REGEX =
  /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/;
export const LOWERCASE_REGEX = /[a-z]/;
export const UPPERCASE_REGEX = /[A-Z]/;
export const NUMBER_REGEX = /\d/;
export const WHITESPACE_REGEX = /\s/;
export const PASSWORD_REGEX =
  /^((?=.*[a-z])(?=.*\d)(?=.*[A-Z])(?!.*\s).{8,64})$/;
export const ISNUMBER = /^\d+$/;
export const PREFIX_REGEX = /["*/:<>?\\{|}]/;
/**
 * Returns length regexp
 *
 * @param min - Minimum amout of letters
 * @param max - Maximum amout of letters
 *
 * @returns {RegExp} Length regexp
 */
export const LENGTH_REGEX = (min: number, max: number): RegExp =>
  new RegExp(`^.{${min},${max}}$`);
