import CustomTableContainer from 'components/Table/CustomTable';
import TableToolbar from 'components/TableToolbar/TableToolbar';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from 'store';
import { getAdministratorsPageable } from 'store/administrator/administrator.actions';
import { getAdministratorsPageableSelector } from 'store/administrator/administrator.selectors';
import { AdministratorDTO, Page } from 'utils/restApplicationClient';

interface IStateProps {
  administratorsPageable?: Page<AdministratorDTO>;
  fetchSpinnerOverride?: boolean;
}

const TestTable = CustomTableContainer<AdministratorDTO>();
export class AdministratorsContainer extends Component<
  RouteComponentProps & IStateProps
> {
  headers = [
    { label: 'common.id', id: 'id' },
    { label: 'common.administratorName', id: 'email' },
    { label: 'common.client', id: 'client.clientName' },
    { label: 'common.status', id: 'status' },
    { label: 'common.creationDate', id: 'createdAt' },
    { label: 'common.modificationDate', id: 'modifiedAt' },
  ];
  onEdit = (row: AdministratorDTO): void => {
    this.props.history.push(`/administrator/edit/${row.id}`);
  };

  onAdd = (): void => {
    this.props.history.push(`/administrator/add`);
  };

  render(): JSX.Element {
    const { administratorsPageable, fetchSpinnerOverride } = this.props;
    return (
      <div>
        <TableToolbar buttonLabelKey="administrator.add" onAdd={this.onAdd} />
        <TestTable
          headers={this.headers}
          onEdit={this.onEdit}
          paginationAction={getAdministratorsPageable}
          pageable={administratorsPageable}
          search={''}
          fetchSpinnerOverride={fetchSpinnerOverride}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  administratorsPageable: getAdministratorsPageableSelector(state),
});

export default connect(mapStateToProps)(withRouter(AdministratorsContainer));
