import {
  Box,
  CircularProgress,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { TreeItem, TreeView } from '@material-ui/lab';
import { uniqueId } from 'lodash-es';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store';
import { getFetchSpinnerSelector } from 'store/spinner/spinner.selectors';
import {
  CenterDrawer,
  CustomDrawer,
  CustomDrawerContainer,
  ErrorListItemText,
  ErrorTreeItem,
} from './DrawerPanel.styled';
import {
  DrawerElementInterface,
  DrawerInterface,
  IStateProps,
} from './DrawerPanel.type';

const Label: FC<{ node: DrawerElementInterface }> = ({
  node: { label, icon },
}) => (
  <Box
    display="flex"
    alignItems="center"
    flexDirection="row"
    className="box-content"
  >
    {icon}
    <span className="box-label">{label}</span>
  </Box>
);

const CustomTreeItem: FC<{ node: DrawerElementInterface }> = ({ node }) => {
  const id = uniqueId();
  return node.error ? (
    <ErrorTreeItem
      key={`${id}${node.label}`}
      nodeId={node.label}
      label={<Label node={node} />}
      onClick={node.onClick}
    >
      {Array.isArray(node.elements)
        ? node.elements.map((element, index) => (
            <CustomTreeItem key={`${index}-${element.label}`} node={element} />
          ))
        : null}
    </ErrorTreeItem>
  ) : (
    <TreeItem
      key={`${id}${node.label}`}
      nodeId={node.label}
      label={<Label node={node} />}
      onClick={node.onClick}
    >
      {Array.isArray(node.elements)
        ? node.elements.map((element, index) => (
            <CustomTreeItem key={`${index}-${element.label}`} node={element} />
          ))
        : null}
    </TreeItem>
  );
};

class DrawerPanel extends React.Component<DrawerInterface & IStateProps> {
  renderEmpty = (): JSX.Element => {
    const { fetchSpinner } = this.props;
    if (fetchSpinner) {
      return (
        <CenterDrawer>
          <CircularProgress color="secondary" size={120} />
        </CenterDrawer>
      );
    } else {
      return <></>;
    }
  };

  render(): JSX.Element {
    const { anchor, group, bottomElements, topElements } = this.props;
    return (
      <CustomDrawer variant="permanent" anchor={anchor}>
        <CustomDrawerContainer>
          <div>
            {topElements?.map((element, id) => (
              <>
                <List key={id}>
                  <Divider />
                  <ListItem
                    key={`${element.label}-${id}`}
                    button
                    onClick={element.onClick}
                  >
                    {element.icon ? (
                      <ListItemIcon>
                        <Icon>{element.icon}</Icon>
                      </ListItemIcon>
                    ) : null}
                    <ListItemText primary={element.label} />
                  </ListItem>
                </List>
                <Divider />
              </>
            ))}
            {this.renderEmpty()}
            {group && group?.length > 0
              ? group?.map((group, id) => (
                  <div>
                    <List key={`${group.title}${id}`}>
                      <p className="group-title">{group.title}</p>
                      {group.elements?.map((element, index) =>
                        element.elements ? (
                          <TreeView
                            key={`${element.label}${index}`}
                            defaultCollapseIcon={<KeyboardArrowDownIcon />}
                            defaultExpandIcon={<KeyboardArrowRightIcon />}
                            onClick={element.onClick}
                          >
                            <CustomTreeItem node={element} />
                          </TreeView>
                        ) : element.error ? (
                          <ListItem
                            key={element.label ? `${element.label}-${id}` : id}
                            button
                            onClick={element.onClick}
                          >
                            {element.icon ? (
                              <ListItemIcon>
                                <Icon>{element.icon}</Icon>
                              </ListItemIcon>
                            ) : null}
                            <ErrorListItemText
                              primary={element.label}
                            ></ErrorListItemText>
                          </ListItem>
                        ) : (
                          <ListItem
                            key={element.label ? `${element.label}-${id}` : id}
                            button
                            onClick={element.onClick}
                          >
                            {element.icon ? (
                              <ListItemIcon>
                                <Icon>{element.icon}</Icon>
                              </ListItemIcon>
                            ) : null}
                            <ListItemText
                              primary={element.label}
                            ></ListItemText>
                          </ListItem>
                        ),
                      )}
                    </List>
                  </div>
                ))
              : group?.map((group) => (
                  <List key={group.title}>
                    {group.elements?.map((element, id) => (
                      <ListItem
                        key={element.label ? `${element.label}-${id}` : id}
                        button
                        onClick={element.onClick}
                      >
                        {element.icon ? (
                          <ListItemIcon>
                            <Icon>{element.icon}</Icon>
                          </ListItemIcon>
                        ) : null}
                        <ListItemText primary={element.label}></ListItemText>
                      </ListItem>
                    ))}
                  </List>
                ))}
          </div>
          {bottomElements?.map((element, id) => (
            <>
              <List key={id}>
                <Divider />
                <ListItem
                  key={`${element.label}-${id}`}
                  button
                  onClick={element.onClick}
                >
                  {element.icon ? (
                    <ListItemIcon>
                      <Icon>{element.icon}</Icon>
                    </ListItemIcon>
                  ) : null}
                  <ListItemText primary={element.label} />
                </ListItem>
              </List>
            </>
          ))}
        </CustomDrawerContainer>
      </CustomDrawer>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  fetchSpinner: getFetchSpinnerSelector(state),
});

export default connect(mapStateToProps)(DrawerPanel);
