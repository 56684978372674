import styled from '@emotion/styled';

export const ModalDropdown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalWindow = styled.div`
  background-color: #eff3f7;
  text-align: center;
  border-radius: 15px;
  border: 2px solid #2e3645;
  width: 300px;
  button:last-child {
    margin-bottom: 20px;
  }
`;

export const ModalLogoBar = styled.div`
  height: 100px;
  width: 100%;
  background-color: #2e3645;
  padding: 20px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  > img {
    height: 100%;
  }
`;
export const ModalTextDiv = styled.div`
  padding: 20px 40px;
  p:first-of-type {
    margin-bottom: 10px;
  }
`;
