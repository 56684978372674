import { Dispatch } from '@reduxjs/toolkit';
import ButtonContainer from 'components/Form/ButtonContainer/ButtonContainer';
import { EditData } from 'components/Form/CilentData/EditData';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomCheckbox from 'components/Form/Input/CustomCheckbox';
import CustomFieldRow from 'components/Form/Input/CustomFieldRow';
import CustomSelect from 'components/Form/Input/CustomSelect';
import FormTitle from 'components/Form/Title/FormTitle';
import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppDispatch, AppState } from 'store';
import {
  clearAdministrator,
  clearAdministratorErrors,
  createAdministrator,
  editAdministrator,
  getAdministratorById,
} from 'store/administrator/administrator.actions';
import {
  getAdministratorErrorsSelector,
  getAdministratorSelector,
} from 'store/administrator/administrator.selectors';
import { getAllClients } from 'store/client/client.actions';
import { getAllClientsSelector } from 'store/client/client.selectors';
import { IEditAdministrator } from 'types/api';
import {
  composeValidators,
  email,
  fetchValidator,
  optionalValidator,
  password,
  required,
} from 'utils/customValidators';
import { AdministratorDTO, ClientDTO } from 'utils/restApplicationClient';
import {
  CreateAdministratorDTO,
  UpdateAdministratorDTO,
} from 'utils/restApplicationClientTypeOverrides';

interface IState {
  submitValues?: CreateAdministratorDTO & UpdateAdministratorDTO;
}

interface IDispatchProps {
  createAdministrator: (payload: CreateAdministratorDTO) => AppDispatch;
  getAllClients: () => AppDispatch;
  clearAdministratorErrors: () => AppDispatch;
  clearAdministrator: () => AppDispatch;
  getAdministratorById: (payload: string) => AppDispatch;
  editAdministrator: (payload: IEditAdministrator) => AppDispatch;
}

interface IStateProps {
  clients: ClientDTO[];
  errors: { [key: string]: string | undefined };
  administrator?: AdministratorDTO;
}

interface IProps {
  native?: boolean;
}

interface IMatchParams {
  id?: string;
}

export class AddAdministratorContainer extends Component<
  WithTranslation &
    RouteComponentProps<IMatchParams> &
    IDispatchProps &
    IStateProps &
    IProps,
  IState
> {
  componentDidMount(): void {
    const {
      match: {
        params: { id: administratorId },
      },
      getAdministratorById,
      getAllClients,
    } = this.props;

    if (administratorId) {
      getAdministratorById(administratorId);
    }
    getAllClients();
  }

  componentWillUnmount(): void {
    const { clearAdministrator, clearAdministratorErrors } = this.props;
    clearAdministratorErrors();
    clearAdministrator();
  }

  parseClientSelect(rows: ClientDTO[]): Array<{ value: string; name: string }> {
    return rows.map((client) => ({
      value: client.id,
      name: client.clientName,
    }));
  }

  onSubmit = (
    values: CreateAdministratorDTO & UpdateAdministratorDTO,
  ): void => {
    const {
      match: {
        params: { id: administratorId },
      },
      createAdministrator,
      editAdministrator,
    } = this.props;
    this.props.clearAdministratorErrors();
    this.setState({ submitValues: values });
    if (administratorId) {
      editAdministrator({ administratorId, updateAdministrator: values });
    } else {
      createAdministrator(values);
    }
  };
  render(): JSX.Element {
    const {
      errors,
      match: {
        params: { id: administratorId },
      },
      administrator,
      native,
    } = this.props;
    return (
      <FormDiv>
        <FormTitle
          text={administratorId ? 'administrator.edit' : 'administrator.add'}
        />
        <Form
          onSubmit={this.onSubmit}
          render={({ handleSubmit, submitting, pristine }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                initialValue={administrator && administrator.email}
                validate={composeValidators([
                  required,
                  email,
                  fetchValidator(
                    errors['email'],
                    this.state?.submitValues?.email,
                  ),
                ])}
                key={
                  errors['email']
                    ? `emailError${this.state?.submitValues?.email}`
                    : 'email'
                }
              >
                {({ input, meta }): JSX.Element => (
                  <CustomFieldRow
                    label="common.email"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={input}
                    testId="emailInput"
                    disableAutoComplete={true}
                  />
                )}
              </Field>
              <Field
                name="password"
                validate={
                  administrator
                    ? (value: string): null | string =>
                        optionalValidator(value, password)
                    : password
                }
              >
                {({ input, meta }): JSX.Element => (
                  <CustomFieldRow
                    label="common.password"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={input}
                    testId="passwordInput"
                    type="password"
                    disableAutoComplete={true}
                  />
                )}
              </Field>
              <Field
                name="status"
                type="checkbox"
                initialValue={
                  administratorId ? administrator && administrator.status : true
                }
              >
                {({ input }): JSX.Element => (
                  <CustomCheckbox
                    label="common.active"
                    inputProps={{ ...input }}
                    testId="checkboxInput"
                  />
                )}
              </Field>
              <Field
                name="clientUUID"
                initialValue={administrator && administrator.client.id}
                validate={required}
              >
                {({ input, meta }): JSX.Element => (
                  <CustomSelect
                    label="common.clientAssigned"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={input}
                    testId="selectInput"
                    rows={this.parseClientSelect(this.props.clients)}
                    selectProps={{ native }}
                  />
                )}
              </Field>
              {administrator && (
                <EditData
                  createdAt={administrator.createdAt}
                  modifiedAt={administrator.modifiedAt}
                />
              )}
              <ButtonContainer
                backTo="/administrators"
                submitting={submitting || pristine}
              />
            </form>
          )}
        />
      </FormDiv>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppDispatch>,
): IDispatchProps => ({
  createAdministrator: (payload: CreateAdministratorDTO): AppDispatch =>
    dispatch(createAdministrator(payload)),
  getAllClients: (): AppDispatch => dispatch(getAllClients()),
  clearAdministratorErrors: (): AppDispatch =>
    dispatch(clearAdministratorErrors()),
  getAdministratorById: (payload: string): AppDispatch =>
    dispatch(getAdministratorById(payload)),
  clearAdministrator: (): AppDispatch => dispatch(clearAdministrator()),
  editAdministrator: (payload: IEditAdministrator): AppDispatch =>
    dispatch(editAdministrator(payload)),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  clients: getAllClientsSelector(state),
  errors: getAdministratorErrorsSelector(state),
  administrator: getAdministratorSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation()(AddAdministratorContainer)));
