import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';

const fetchSpinnerSelector = ({
  spinner: { fetchSpinner },
}: AppState): boolean => fetchSpinner;

const templateSpinnerSelector = ({
  spinner: { templateSpinner },
}: AppState): boolean => templateSpinner;

const customerSpinnerSelector = ({
  spinner: { customerSpinner },
}: AppState): boolean => customerSpinner;

const connectDataSpinnerSelector = ({
  spinner: { connectDataSpinner },
}: AppState): boolean => connectDataSpinner;

const connectToXtmSpinnerSelector = ({
  spinner: { connectToXtmSpinner },
}: AppState): boolean => connectToXtmSpinner;

const connectToCMSSpinnerSelector = ({
  spinner: { connectToCMSSpinner },
}: AppState): boolean => connectToCMSSpinner;

const settingsSpinnerSelector = ({
  spinner: { settingsSpinner },
}: AppState): boolean => settingsSpinner;

export const getFetchSpinnerSelector = createSelector(
  fetchSpinnerSelector,
  (fetchSelector) => fetchSelector,
);

export const getTemplateSpinnerSelector = createSelector(
  templateSpinnerSelector,
  (templateSelector) => templateSelector,
);

export const getCustomerSpinnerSelector = createSelector(
  customerSpinnerSelector,
  (customerSpinner) => customerSpinner,
);
export const getconnectDataSpinnerSelector = createSelector(
  connectDataSpinnerSelector,
  (connectDataSpinner) => connectDataSpinner,
);

export const getConnectToXtmSpinnerSelector = createSelector(
  connectToXtmSpinnerSelector,
  (connectToXtmSpinner) => connectToXtmSpinner,
);

export const getConnectToCMSSpinnerSelector = createSelector(
  connectToCMSSpinnerSelector,
  (connectToCMSSpinner) => connectToCMSSpinner,
);

export const getSettingsSpinnerSelector = createSelector(
  settingsSpinnerSelector,
  (settingsSpinner) => settingsSpinner,
);
