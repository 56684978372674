import LoginContainer from 'containers/Login/LoginContainer';
import ResetPasswordContainer from 'containers/ResetPassword/ResetPasswordContainer';
import { IRoute } from 'types/route';
import ChangePasswordContainer from '../../containers/ChangePasswordContainer/ChangePasswordContainer';

export const loginRoutes: IRoute[] = [
  {
    pathname: '/login',
    component: LoginContainer,
    exact: true,
  },
  {
    pathname: '/reset-password/:type',
    component: ResetPasswordContainer,
    exact: true,
  },
  {
    pathname: '/changePassword',
    component: ChangePasswordContainer,
    exact: false,
  },
];
