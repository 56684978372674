export const AdministratorActionTypes = {
  CreateAdministrator: 'ADMINISTRATOR_CREATE_ADMINISTRATOR',
  CreateAdministratorError: 'ADMINISTRATOR_CREATE_ADMINISTRATOR_ERROR',
  CreateAdministratorSuccess: 'ADMINISTRATOR_CREATE_ADMINISTRATOR_SUCCESS',
  ClearAdministratorErrors: 'ADMINISTRATOR_CLEAR_ERRORS',
  GetAdministatorsPageable: 'ADMINISTRATOR_GET_ADMINISTRATORS_PAGEABLE',
  GetAdministratorsPageableSuccess:
    'ADMINISTRATOR_GET_ADMINISTRATORS_PAGEABLE_SUCCESS',
  GetAdministratorsPageableError:
    'ADMINISTRATOR_GET_ADMINISTRATORS_PAGEABLE_ERROR',
  GetAdministratorById: 'ADMINISTRATOR_GET_ADMINISTRATOR_BY_ID',
  GetAdministratorByIdSuccess: 'ADMINISTRATOR_GET_ADMINISTRATOR_BY_ID_SUCCESS',
  GetAdministratorByIdError: 'ADMINISTRATOR_GET_ADMINISTRATOR_BY_ID_ERROR',
  ClearAdministrator: 'ADMINISTRATOR_CLEAR_ADMINISTRATOR',
  EditAdministrator: 'ADMINISTRATOR_EDIT_ADMINISTRATOR',
  EditAdministratorSuccess: 'ADMINISTRATOR_EDIT_ADMINISTRATOR_SUCCESS',
  EditAdministratorError: 'ADMINISTRATOR_EDIT_ADMINISTRATOR_ERROR',
};
