import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { IClientState } from './client.interface';

export const clientStateSelector = (state: AppState): IClientState =>
  state.client;

export const getClientsPageableSelector = createSelector(
  clientStateSelector,
  ({ clientsPageable }) => clientsPageable,
);

export const getAllClientsSelector = createSelector(
  clientStateSelector,
  ({ clients }) => clients,
);

export const getClientErrorsSelector = createSelector(
  clientStateSelector,
  ({ errors }) => errors,
);

export const getClientSelector = createSelector(
  clientStateSelector,
  ({ client }) => client,
);

export const getXTMLanguagesSelector = createSelector(
  clientStateSelector,
  ({ xtmLanguages }) => xtmLanguages,
);

export const getContentfulLanguagesSelector = createSelector(
  clientStateSelector,
  ({ contentfulLanguages }) => contentfulLanguages,
);
