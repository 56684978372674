/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import CustomAutocomplete from 'components/Form/Input/CustomAutocomplete';
import { IClientForm } from 'containers/AddClientContainer/AddClientContainer';
import { FormApi } from 'final-form';
import { Component } from 'react';
import { Field } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAutocompleteField, ILanguageMappingField } from 'types/shared';
import { requiredAutocomplete } from 'utils/customValidators';
import {
  CustomLanguageMappingButton,
  CustomLanguageMappingContainer,
  CustomLanguageMappingFieldContainer,
  CustomLanguageMappingLegend,
  CustomLanguageMappingRemoveButton,
} from './LanguageMappingContainer.styled';

interface IState {
  filteredLanguages: IAutocompleteField[][];
}

interface IProps {
  form: FormApi<IClientForm>;
  contentfulLanguages: IAutocompleteField[];
  xtmLanguages: IAutocompleteField[];
  initialValue?: Array<ILanguageMappingField>;
}

type PropType = IProps & WithTranslation;

export class LanguageMappingContainer extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);

    this.state = {
      filteredLanguages: [],
    };
  }

  componentDidUpdate(): void {
    const { form, contentfulLanguages } = this.props;
    const { filteredLanguages } = this.state;

    const languageMappingFields = form.getFieldState(
      'contentfulXtmLanguagesMap',
    )?.value;

    if (
      languageMappingFields?.length &&
      contentfulLanguages &&
      !filteredLanguages?.length
    ) {
      this.setFilteredLanguages();
    }
  }

  setFilteredLanguages(): void {
    const { form, contentfulLanguages } = this.props;

    setTimeout(() => {
      const languageMappingFields = form.getFieldState(
        'contentfulXtmLanguagesMap',
      )?.value;

      let filteredLanguages: IAutocompleteField[][] = [];

      const chosenContentfulLanguages: string[] =
        languageMappingFields?.map(
          ({ contentfulLanguage }) => contentfulLanguage?.value,
        ) || [];

      filteredLanguages =
        languageMappingFields?.map((language, index) =>
          contentfulLanguages.filter(
            ({ value }) =>
              !chosenContentfulLanguages.includes(value) ||
              languageMappingFields[index].contentfulLanguage?.value === value,
          ),
        ) || [];

      this.setState({
        filteredLanguages,
      });
    }, 1);
  }

  getContentfulLanguageValue(index: number): string | undefined {
    const { form } = this.props;
    const languageMappingField = form.getFieldState(
      'contentfulXtmLanguagesMap',
    )?.value;

    return (
      languageMappingField &&
      languageMappingField[index].contentfulLanguage?.value
    );
  }

  handleAddLanguageMapping =
    (
      fields: FieldArrayRenderProps<
        ILanguageMappingField,
        HTMLElement
      >['fields'],
    ) =>
    (): void => {
      fields.push({
        xtmLanguage: { label: '', value: '' },
        contentfulLanguage: { label: '', value: '' },
      });
      this.setFilteredLanguages();
    };

  handleRemoveLanguageMapping =
    (
      fields: FieldArrayRenderProps<
        ILanguageMappingField,
        HTMLElement
      >['fields'],
      index: number,
    ) =>
    (): void => {
      fields.remove(index);
      this.setFilteredLanguages();
    };

  onContentfulLanguageChange =
    (
      index: number,
      fields: FieldArrayRenderProps<
        ILanguageMappingField,
        HTMLElement
      >['fields'],
    ) =>
    (
      event: React.ChangeEvent<{}>,
      value: IAutocompleteField | Array<IAutocompleteField> | null,
    ): void => {
      if (!value) {
        fields.update(index, {
          contentfulLanguage: { label: '', value: '' },
          xtmLanguage: { label: '', value: '' },
        });
      }

      this.setFilteredLanguages();
    };

  render(): JSX.Element {
    const { filteredLanguages } = this.state;
    const { t, contentfulLanguages, xtmLanguages, initialValue } = this.props;

    return (
      <FieldArray name="contentfulXtmLanguagesMap" initialValue={initialValue}>
        {({ fields }): JSX.Element => (
          <CustomLanguageMappingContainer>
            <CustomLanguageMappingLegend>
              {t('client.languageMapping')}
            </CustomLanguageMappingLegend>
            {fields.map(
              (name, index: number): JSX.Element => (
                <Box
                  display="flex"
                  key={name}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <CustomLanguageMappingFieldContainer>
                    <Field
                      name={`${name}.contentfulLanguage`}
                      validate={requiredAutocomplete}
                      label="client.contentfulLanguage"
                      options={filteredLanguages[index] || []}
                      component={CustomAutocomplete}
                      column
                      placeholder={t('common.selectPlaceholder')}
                      onChange={this.onContentfulLanguageChange(index, fields)}
                    />
                  </CustomLanguageMappingFieldContainer>

                  <CustomLanguageMappingFieldContainer>
                    <Field
                      name={`${name}.xtmLanguage`}
                      validate={requiredAutocomplete}
                      label="client.xtmLanguage"
                      options={xtmLanguages}
                      component={CustomAutocomplete}
                      column
                      placeholder={t('common.selectPlaceholder')}
                      disabled={!this.getContentfulLanguageValue(index)}
                    />
                  </CustomLanguageMappingFieldContainer>

                  <CustomLanguageMappingRemoveButton
                    onClick={this.handleRemoveLanguageMapping(fields, index)}
                  >
                    <CloseIcon />
                  </CustomLanguageMappingRemoveButton>
                </Box>
              ),
            )}
            {contentfulLanguages?.length !== fields?.length && (
              <CustomLanguageMappingButton
                onClick={this.handleAddLanguageMapping(fields)}
              >
                <AddCircleIcon fontSize="large" />
              </CustomLanguageMappingButton>
            )}
          </CustomLanguageMappingContainer>
        )}
      </FieldArray>
    );
  }
}

export default withTranslation()(LanguageMappingContainer);
