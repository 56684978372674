import { createAction } from '@reduxjs/toolkit';
import { SpinnerActionTypes } from './spinner.actions.types';

export const setFetchSpinner = createAction<boolean>(
  SpinnerActionTypes.setFetchSpinner,
);

export const setSettingsSpinner = createAction<boolean>(
  SpinnerActionTypes.setSettingsSpinner,
);

export const setTemplateSpinner = createAction<boolean>(
  SpinnerActionTypes.setTemplateSpinner,
);

export const setCustomerSpinner = createAction<boolean>(
  SpinnerActionTypes.setCustomerSpinner,
);

export const setConnectDataSpinner = createAction<boolean>(
  SpinnerActionTypes.setConnectDataSpinner,
);

export const setConnectToXtmSpinner = createAction<boolean>(
  SpinnerActionTypes.setConnectToXtmSpinner,
);

export const setConnectToCMSSpinner = createAction<boolean>(
  SpinnerActionTypes.setConnectToCMSSpinner,
);
