import { createAction } from '@reduxjs/toolkit';
import {
  OrganizationSettingsDTO,
  Page,
  PageableSettingsDTO,
  SettingsDTO,
} from 'utils/restApplicationClient';
import { SettingsActionTypes } from './settings.actions.types';
import {
  ISettingsPageable,
  SaveOrganizationSettings,
  SettingsAuthorization,
} from './settings.interface';

export const fetchSettings = createAction(SettingsActionTypes.fetchSettings);

export const fetchSettingsSuccess = createAction<SettingsDTO[]>(
  SettingsActionTypes.fetchSettingsSuccess,
);

export const fetchSettingsError = createAction(
  SettingsActionTypes.fetchSettingsError,
);

export const getSettings = createAction<string>(
  SettingsActionTypes.getSettings,
);

export const getSettingsSuccess = createAction<SettingsDTO[]>(
  SettingsActionTypes.getSettingsSuccess,
);

export const getSettingsError = createAction(
  SettingsActionTypes.getSettingsError,
);

export const getAllSettings = createAction<ISettingsPageable>(
  SettingsActionTypes.getAllSettings,
);

export const getAllSettingsSuccess = createAction<Page<PageableSettingsDTO>>(
  SettingsActionTypes.getAllSettingsSuccess,
);

export const getAllSettingsError = createAction(
  SettingsActionTypes.getAllSettingsError,
);

export const initializeContentful = createAction<SettingsAuthorization>(
  SettingsActionTypes.InitializeContentful,
);

export const initializeContentfulSuccess = createAction<SettingsDTO[]>(
  SettingsActionTypes.InitializeContentfulSuccess,
);

export const initializeContentfulError = createAction<string | undefined>(
  SettingsActionTypes.InitializeContentfulError,
);

export const initializationCheck = createAction(
  SettingsActionTypes.InitializationCheck,
);

export const initializationCheckSuccess = createAction<boolean>(
  SettingsActionTypes.InitializationCheckSuccess,
);

export const initializationCheckError = createAction<string | undefined>(
  SettingsActionTypes.InitializationCheckError,
);

export const addSpace = createAction<boolean>(SettingsActionTypes.addSpace);

export const setClientId = createAction<string>(
  SettingsActionTypes.setClientID,
);

export const deleteOrganizationSettings = createAction<string>(
  SettingsActionTypes.deleteOrganizationSettings,
);

export const deleteOrganizationSettingsSuccess = createAction<
  string | undefined
>(SettingsActionTypes.deleteOrganizationSettingsSuccess);

export const deleteOrganizationSettingsError = createAction<string | undefined>(
  SettingsActionTypes.deleteOrganizationSettingsError,
);

export const updateSettings = createAction<SaveOrganizationSettings>(
  SettingsActionTypes.updateSettings,
);

export const updateSettingsError = createAction(
  SettingsActionTypes.updateSettingsError,
);

export const updateSettingsSuccess = createAction<string | undefined>(
  SettingsActionTypes.updateSettingsSuccess,
);

export const setCurrentOrganizationSettings = createAction<
  OrganizationSettingsDTO | undefined
>(SettingsActionTypes.setCurrentOrganizationSettings);

export const changeCredentials = createAction<SettingsAuthorization>(
  SettingsActionTypes.changeCredentials,
);
export const changeCredentialsSuccess = createAction(
  SettingsActionTypes.changeCredentialsSuccess,
);
export const changeCredentialsError = createAction<string | undefined>(
  SettingsActionTypes.changeCredentialsError,
);
