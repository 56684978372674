/* eslint-disable no-unused-expressions */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, BoxProps, CircularProgress, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import React, {
  ChangeEvent,
  Component,
  ComponentType,
  createRef,
  Fragment,
  MouseEventHandler,
} from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
  OnDragStartResponder,
} from 'react-beautiful-dnd';
import { FieldRenderProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IAutocompleteField } from 'types/shared';
import {
  CustomAutocompleteTypography,
  CustomTypography,
  InputDiv,
} from '../CustomInput.style';
import {
  DraggableSelectActionButton,
  DraggableSelectArrowContainer,
  DraggableSelectAutocompletePaper,
  DraggableSelectButtonContainer,
  DraggableSelectChip,
  DraggableSelectChipContainer,
  DraggableSelectDataContainer,
  DraggableSelectDefaultChip,
  DraggableSelectEllipsis,
  DraggableSelectEllipsisContainer,
  DraggableSelectIconButton,
  DraggableSelectInput,
  DraggableSelectInputContainer,
  DraggableSelectOptionCheckbox,
  DraggableSelectPopper,
  DraggableSelectSelectClasses,
  DraggableSelectSpinnerContainer,
} from './DraggableSelect.styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IProps extends FieldRenderProps<any> {
  options: Array<IAutocompleteField>;
  onChange?: (value: Array<IAutocompleteField> | null) => void;
  onHandleChange?: (
    selectedCustomers: Array<IAutocompleteField> | null,
    value: Array<IAutocompleteField> | null,
  ) => void;
  handleChipDelete?: (index: number) => void;
  handleSelectAll?: (
    selectedCustomers: Array<IAutocompleteField>,
    options: Array<IAutocompleteField>,
  ) => void;
  handleClearAll?: (selectedCustomers: Array<IAutocompleteField>) => void;
  handleDragEnd?: (destination: number, source: number) => void;
  column?: boolean;
  label: string;
  noOptionsText?: string;
  error?: string;
  spinner?: boolean;
  disabled?: boolean;
  placeholder?: string;
  allowDragAndDrop?: boolean;
  width?: number;
  handleOnClose?: (
    elements: Array<IAutocompleteField> | null | undefined,
  ) => void;
  handleOnChipDelete?: (
    elements: Array<IAutocompleteField> | null | undefined,
  ) => void;
}

interface IState {
  canOpen: boolean;
  id?: string;
  isDragged: boolean;
  isHovered: boolean;
  isOpened: boolean;
  chipTotalHeight: number;
  anchorEl?: EventTarget & HTMLDivElement;
  elements: Array<IAutocompleteField> | null;
}

type PropType = IProps & WithTranslation;

export class DraggableSelect extends Component<PropType, IState> {
  private inputContainerRef =
    createRef<
      Component<BoxProps, Record<string, unknown>, Record<string, unknown>>
    >();
  private arrowRef =
    createRef<
      Component<BoxProps, Record<string, unknown>, Record<string, unknown>>
    >();
  private draggableContextRef =
    createRef<
      Component<BoxProps, Record<string, unknown>, Record<string, unknown>>
    >();

  private chipElements: HTMLElement[] = [];
  private draggableContextWidth = 0;

  constructor(props: PropType) {
    super(props);
    this.state = {
      canOpen: true,
      isDragged: false,
      isHovered: false,
      isOpened: false,
      chipTotalHeight: 32,
      elements: null,
    };
  }

  componentDidUpdate(): void {
    const draggableElement = this.draggableContextRef
      ?.current as unknown as HTMLElement;
    const arrowElement = this.arrowRef?.current as unknown as HTMLElement;
    // eslint-disable-next-line unicorn/prefer-spread
    const chipElements: HTMLElement[] = Array.from(
      draggableElement?.querySelectorAll('.MuiChip-root') || [],
    );
    const draggableContextWidth =
      draggableElement?.offsetWidth - arrowElement?.offsetWidth;

    if (
      this.chipElements?.length !== chipElements.length ||
      draggableContextWidth !== this.draggableContextWidth
    ) {
      this.chipElements = chipElements;
      this.draggableContextWidth = draggableContextWidth;
    }

    this.checkChipContainerHeight();
  }

  handleChange = (
    event: React.ChangeEvent<{}>,
    value: Array<IAutocompleteField> | null,
  ): void => {
    const { onChange, input, onHandleChange, disabled, spinner } = this.props;

    if (!disabled && !spinner) {
      onChange?.(value);
      onHandleChange?.(input.value, value);

      this.setState({ elements: value });
      input.onChange(value);
    }
  };

  handleClick: MouseEventHandler<HTMLDivElement> = (event): void => {
    const { disabled } = this.props;
    const { anchorEl, canOpen, isDragged } = this.state;

    if (canOpen && !isDragged && !disabled) {
      this.setState({
        anchorEl: anchorEl ? undefined : event.currentTarget,
        canOpen: false,
      });
    }
  };

  handleClose = (event?: ChangeEvent<{}>): void => {
    const { handleOnClose } = this.props;
    const { elements } = this.state;
    event?.preventDefault();
    handleOnClose?.(elements);
    if (event?.nativeEvent.type === 'blur') {
      this.setState({ anchorEl: undefined });
      setTimeout(() => {
        this.setState({ canOpen: true });
      }, 100);
    }
  };

  onChipDelete = (value: string) => (): void => {
    const { input, onChange, handleChipDelete, handleOnChipDelete } =
      this.props;

    const data = (input.value as IAutocompleteField[])?.filter(
      (record, index) => {
        if (record.value !== value) {
          return true;
        }
        handleChipDelete?.(index);
        return false;
      },
    );

    this.setState({ elements: data });
    input.onChange(data);
    onChange?.(data);
    handleOnChipDelete?.(data);
  };

  onSelectAll = (): void => {
    const {
      input,
      options,
      onChange,
      handleSelectAll,
      disabled,
      spinner,
      handleOnClose,
    } = this.props;
    if (!disabled && !spinner) {
      handleSelectAll?.(input.value, options);
      handleOnClose?.(options);
      input.onChange(options);
      onChange?.(options);
    }
  };

  onClearAll = (): void => {
    const {
      input,
      onChange,
      handleClearAll,
      disabled,
      spinner,
      handleOnClose,
    } = this.props;

    if (!disabled && !spinner) {
      handleClearAll?.(input.value);
      input.onChange([]);
      onChange?.(null);
      handleOnClose?.([]);
    }
  };

  onDragEnd: OnDragEndResponder = ({ destination, source }) => {
    const {
      input: { value, onChange },
      onChange: customOnChange,
      handleDragEnd,
    } = this.props;

    if (!destination) {
      return;
    }

    const filteredData = value as IAutocompleteField<string>[];
    const [removed] = filteredData.splice(source.index, 1);

    handleDragEnd?.(destination.index, source.index);

    filteredData.splice(destination.index, 0, removed);
    onChange(filteredData);
    customOnChange?.(filteredData);
    this.setState({ isDragged: false });
  };

  onDragStart: OnDragStartResponder = (): void => {
    this.setState({ isDragged: true });
  };

  checkIfOverflowed = (): boolean => {
    let width = 0;

    return this.chipElements?.some((node) => {
      width += node.offsetWidth;
      return width > this.draggableContextWidth;
    });
  };

  handleInputMouseEvent = (value: boolean) => (): void => {
    this.setState({ isHovered: value });
  };

  checkChipContainerHeight = (): void => {
    const chipParentHeight = this.chipElements[0]?.parentElement?.offsetHeight;
    let count = 1;
    let width = 0;
    this.chipElements?.forEach((node) => {
      width += node.offsetWidth;

      if (width > this.draggableContextWidth) {
        count += 1;
        width = 0;
      }
    });
    const chipTotalHeight = count * (chipParentHeight ?? 0);

    if (this.state.chipTotalHeight !== chipTotalHeight) {
      this.setState({ chipTotalHeight });
    }
  };

  render(): JSX.Element {
    const {
      options,
      column,
      input,
      label,
      t,
      meta,
      spinner,
      disabled,
      noOptionsText,
      width,
      allowDragAndDrop,
      placeholder,
      error,
    } = this.props;
    const { anchorEl, isHovered, chipTotalHeight } = this.state;
    const inputValue = input.value as IAutocompleteField[];
    const popperElement = this.inputContainerRef
      .current as unknown as HTMLElement;
    const popperWidth = popperElement?.offsetWidth ?? 0;
    return (
      <InputDiv column={column}>
        <CustomAutocompleteTypography column={column && 'true'} component="div">
          <Box fontWeight={500} width="276px" fontSize="14px" color="#333">
            {t(label)}
          </Box>
        </CustomAutocompleteTypography>
        <Box width="100%">
          <Box width="100%">
            <DraggableSelectDataContainer
              onClick={this.handleClick}
              error={Number(!!error || !!(meta.error && meta.touched))}
              height={chipTotalHeight}
              opened={Number(!!anchorEl)}
              hovered={Number(isHovered || !!anchorEl)}
              onMouseEnter={this.handleInputMouseEvent(true)}
              onMouseLeave={this.handleInputMouseEvent(false)}
              width={width}
              ref={this.inputContainerRef}
            >
              <DraggableSelectChipContainer ref={this.draggableContextRef}>
                <DragDropContext
                  onDragEnd={this.onDragEnd}
                  onDragStart={this.onDragStart}
                >
                  {inputValue &&
                    inputValue?.map(({ label, value }, index) => (
                      <Droppable
                        key={index}
                        droppableId={index.toString()}
                        direction="vertical"
                      >
                        {(provided): JSX.Element => (
                          <div ref={provided.innerRef}>
                            {provided.placeholder}

                            <Draggable
                              draggableId={index.toString()}
                              index={index}
                              isDragDisabled={!allowDragAndDrop}
                            >
                              {(provided): JSX.Element => (
                                <DraggableSelectChip
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  disabled={disabled}
                                  deleteIcon={<CloseIcon />}
                                  label={
                                    <div>
                                      {label}
                                      {index === 0 && allowDragAndDrop && (
                                        <DraggableSelectDefaultChip>{`(${t(
                                          'common.default',
                                        )})`}</DraggableSelectDefaultChip>
                                      )}
                                    </div>
                                  }
                                  size="small"
                                  onDelete={this.onChipDelete(value)}
                                />
                              )}
                            </Draggable>
                          </div>
                        )}
                      </Droppable>
                    ))}
                </DragDropContext>
              </DraggableSelectChipContainer>

              <DraggableSelectSpinnerContainer spinner={Number(!!spinner)}>
                <CircularProgress size={12} />
              </DraggableSelectSpinnerContainer>

              {this.checkIfOverflowed() && !isHovered && !anchorEl && (
                <DraggableSelectEllipsisContainer
                  disabled={Number(!!disabled)}
                  spinner={Number(!!spinner)}
                >
                  <DraggableSelectEllipsis>...</DraggableSelectEllipsis>
                </DraggableSelectEllipsisContainer>
              )}

              <DraggableSelectArrowContainer
                ref={this.arrowRef}
                disabled={Number(!!disabled)}
                spinner={Number(!!spinner)}
              >
                <DraggableSelectIconButton>
                  <ArrowDropDownIcon
                    style={{ transform: !!anchorEl ? `rotate(180deg)` : `` }}
                  />
                </DraggableSelectIconButton>
              </DraggableSelectArrowContainer>
            </DraggableSelectDataContainer>
            <DraggableSelectPopper
              open={!!anchorEl}
              anchorEl={anchorEl}
              placement="bottom-end"
              width={popperWidth}
            >
              <Autocomplete
                css={DraggableSelectSelectClasses.autocomplete}
                PaperComponent={(props): JSX.Element => (
                  <DraggableSelectAutocompletePaper
                    {...props}
                    width={popperWidth}
                  />
                )}
                onClose={this.handleClose}
                multiple
                options={options}
                disablePortal
                disableCloseOnSelect={true}
                noOptionsText={!options.length ? noOptionsText : undefined}
                getOptionLabel={(option): string =>
                  option.label ? option.label : ''
                }
                onChange={this.handleChange}
                getOptionSelected={(option, value): boolean =>
                  value.label === option.label
                }
                value={input.value ? input.value : []}
                autoComplete={true}
                open
                renderInput={(parameters): JSX.Element => (
                  <Fragment>
                    <DraggableSelectInputContainer>
                      <DraggableSelectInput
                        ref={parameters.InputProps.ref}
                        inputProps={parameters.inputProps}
                        disabled={disabled || spinner}
                        endAdornment={<SearchIcon />}
                        placeholder={
                          placeholder
                            ? placeholder
                            : t('common.searchPlaceholder')
                        }
                        autoFocus
                      ></DraggableSelectInput>
                    </DraggableSelectInputContainer>
                    <DraggableSelectButtonContainer>
                      <DraggableSelectActionButton onClick={this.onSelectAll}>
                        <Typography>Select all</Typography>
                      </DraggableSelectActionButton>
                      <DraggableSelectActionButton onClick={this.onClearAll}>
                        <Typography>Clear</Typography>
                      </DraggableSelectActionButton>
                    </DraggableSelectButtonContainer>
                  </Fragment>
                )}
                renderOption={({ label }, { selected }): JSX.Element => (
                  <Fragment>
                    <DraggableSelectOptionCheckbox
                      checked={selected}
                      disabled={disabled || spinner}
                    />
                    <CustomTypography color="textSecondary" variant="subtitle2">
                      {label}
                    </CustomTypography>
                  </Fragment>
                )}
                size="small"
              />
            </DraggableSelectPopper>
          </Box>

          {(error || (meta.error && meta.touched)) && (
            <Typography variant="caption" color="error" component="div">
              <Box marginTop="4px">{t(error || meta.error)}</Box>
            </Typography>
          )}
        </Box>
      </InputDiv>
    );
  }
}

export default withTranslation()(DraggableSelect) as ComponentType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FieldRenderProps<any, HTMLElement>
>;
