import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// eslint-disable-next-line id-match
import { initReactI18next } from 'react-i18next';
import locales from 'locales';

const resources = locales;

const i18n = i18next;

/**
 * Initializes i18next module with translation from src/locales
 */
export function initTranslation(): void {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });
}

export default i18n;
