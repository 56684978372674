import { css } from '@emotion/core';

export const loginContainerStyles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
  `,
  button: css`
    margin-top: 20px !important;
    width: 75%;
  `,
  form: css`
    margin: 16px;
  `,
};
