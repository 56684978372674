import styled from '@emotion/styled';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldDiv } from '../Input/CustomInput.style';

interface IProps {
  createdAt: Date;
  modifiedAt: Date;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: bold;
  color: #333;
`;

const Date = styled.p`
  font-weight: 400;
  width: 400px;
`;

export const EditData = (props: IProps): JSX.Element => {
  const { createdAt, modifiedAt } = props;
  const { t } = useTranslation();
  return (
    <Container data-testid="editData">
      <FieldDiv>
        <p>{t('common.creationDate')}</p>
        <Date>{moment(createdAt).format('LLL')}</Date>
      </FieldDiv>
      <FieldDiv>
        <p>{t('common.modificationDate')}</p>
        <Date>{moment(modifiedAt).format('LLL')}</Date>
      </FieldDiv>
    </Container>
  );
};
