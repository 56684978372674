/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { appStyle } from 'App.style';
import SessionExpiredModal from 'components/SessionExpiredModal/SessionExpiredModal';
import LoginLayoutComponent from 'layouts/login';
import { loginRoutes } from 'layouts/login/routes';
import MainLayoutComponent from 'layouts/main';
import { ProviderContext, SnackbarProvider } from 'notistack';
import React, { RefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { AppDispatch } from 'store';
import { verifyToken } from 'store/user/user.actions';
import {
  getAuthSelector,
  getVerifyFinishSelector,
} from 'store/user/user.selectors';
import browserHistory from 'utils/browserHistory';
import NotifyAlert from './components/NotifyAlert/NotifyAlert';

const notistackRef: RefObject<ProviderContext> = React.createRef();
const onClickDismiss = (key: string | number) => (): void => {
  if (notistackRef.current) {
    notistackRef.current.closeSnackbar(key);
  }
};

function App(): JSX.Element {
  const isAuth = useSelector(getAuthSelector);
  const verifyFinished = useSelector(getVerifyFinishSelector);
  const dispatch = useDispatch<Dispatch<AppDispatch>>();

  useEffect(() => {
    dispatch(verifyToken());
  }, []);

  const mainLayoutRoute = (): JSX.Element => {
    if (!isAuth && !verifyFinished) {
      return <CircularProgress />;
    } else if (!isAuth && verifyFinished) {
      return <Redirect to="/login" />;
    }
    return <MainLayoutComponent />;
  };
  return (
    <SnackbarProvider
      ref={notistackRef}
      action={(key): JSX.Element => (
        <IconButton onClick={onClickDismiss(key)}>
          <Box color="#fff">
            <ClearIcon />
          </Box>
        </IconButton>
      )}
    >
      {verifyFinished && (
        <div css={appStyle}>
          <NotifyAlert />
          <SessionExpiredModal />
          <Router history={browserHistory}>
            <Switch>
              {loginRoutes.map((route) => {
                return (
                  <Route
                    exact={route.exact}
                    key={route.pathname}
                    path={route.pathname}
                    render={(): JSX.Element => {
                      return isAuth ? (
                        <Redirect to="/" />
                      ) : (
                        <LoginLayoutComponent />
                      );
                    }}
                  />
                );
              })}
              <Route exact path="/*" render={mainLayoutRoute} />
            </Switch>
          </Router>
        </div>
      )}
    </SnackbarProvider>
  );
}

export default App;
