import { createSelector } from '@reduxjs/toolkit';
import { Page, UserDTO } from '../../utils/restApplicationClient';
import { AppState } from '../index';

const usersPageableSelector = (state: AppState): Page<UserDTO> | undefined =>
  state.user.usersPageable;

const allUsersSelector = (state: AppState): UserDTO[] => state.user.users;

const userSelector = (state: AppState): UserDTO | undefined => state.user.user;

export const getUsersPageableSelector = createSelector(
  usersPageableSelector,
  (users) => users,
);

export const getAllUsersSelector = createSelector(
  allUsersSelector,
  (users) => users,
);

export const getUserSelector = createSelector(
  userSelector,
  (updateUser) => updateUser,
);
