import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  AuthenticationResult,
  AxiosRestApplicationClient,
} from './restApplicationClient';

const axiosInstance = Axios.create();
export const tokenStorageKey = '___TOKEN_KEY___';
export const refreshTokenStorageKey = '___TOKEN2_KEY___';
export const tokenTtlStorageKey = '___TOKEN3_KEY___';

/**
 * request interceptor
 *
 * @param config - axios default configuration
 */
const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  let headers: AxiosRequestConfig['headers'];
  const accessToken = localStorage.getItem(tokenStorageKey);
  const refreshToken = localStorage.getItem(refreshTokenStorageKey);

  if (config.url && config.url.includes('refreshToken')) {
    headers = {
      Authorization: `${accessToken}`,
      'X-Authorization': `${refreshToken}`,
    };
  } else {
    headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  config.headers = headers;
  return config;
};

/**
 * response interceptor
 *
 * @param response - axios response
 */
const responseInterceptor = (
  response: AxiosResponse<unknown>,
): AxiosResponse<unknown> => {
  if (
    response.config.url === 'api/auth/admin' ||
    response.config.url === 'api/auth/refreshToken'
  ) {
    const { accessToken, refreshToken, ttl } =
      response.data as AuthenticationResult;
    localStorage.setItem(tokenStorageKey, accessToken);
    localStorage.setItem(refreshTokenStorageKey, refreshToken);
    localStorage.setItem(tokenTtlStorageKey, String(ttl));
  }

  return response;
};

axiosInstance.interceptors.request.use(requestInterceptor);
axiosInstance.interceptors.response.use(responseInterceptor);

// eslint-disable-next-line require-jsdoc
export const clearToken = (): void => {
  localStorage.removeItem(tokenStorageKey);
  localStorage.removeItem(refreshTokenStorageKey);
  localStorage.removeItem(tokenTtlStorageKey);
};

// eslint-disable-next-line require-jsdoc
export const getTTL = (): number => {
  return Number(localStorage.getItem(tokenTtlStorageKey));
};

let api: AxiosRestApplicationClient;

// eslint-disable-next-line require-jsdoc
const initializeApi = (): void => {
  api = new AxiosRestApplicationClient(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)._env_?.REACT_APP_BACKEND_URL || '/',
    axiosInstance,
  );
};

export { api, initializeApi };
export default axiosInstance;
