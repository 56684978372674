/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Typography } from '@material-ui/core';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { tableEmptyStyles } from './TableEmpty.style';

class TableEmpty extends PureComponent<WithTranslation> {
  render(): JSX.Element {
    const { t } = this.props;
    return (
      <div css={tableEmptyStyles.root} data-testid="tableEmpty">
        <SpeakerNotesOffIcon color="disabled" css={tableEmptyStyles.icon} />
        <Typography css={tableEmptyStyles.text}>
          {t('table.tableEmpty')}
        </Typography>
      </div>
    );
  }
}

export default withTranslation()(TableEmpty);
