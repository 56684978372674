import moment from 'moment';
import { IDateFields } from 'types/api';

interface IResponsePayload<Content> {
  content: Content[];
}

/**
 * Returns object with parsed date fields
 *
 * @param content - Database entity object with createdAt and modifiedAt fields
 *
 * @returns - Database entity object with parsed date fields
 */
export function parseDate<Content extends IDateFields>(
  content: Content,
): Content {
  const { createdAt, modifiedAt } = content;
  const createdAtParsed = moment(createdAt).format('LLL');
  const modifiedAtParsed = moment(modifiedAt).format('LLL');
  return {
    ...content,
    createdAt: createdAtParsed,
    modifiedAt: modifiedAtParsed,
  };
}

/**
 * Returns an array of objects with parsed date fields
 *
 * @param payload - Database entity object with "content" field containing an array of objects with createdAt and modifiedAt fields
 *
 * @returns - Database entity object array with parsed date fields
 */
export function parseDateBulk<
  Content extends IDateFields,
  Payload extends IResponsePayload<Content>,
>(payload: Payload): Payload {
  const { content } = payload;
  const contentParsed = content.map((element) => parseDate(element));
  return { ...payload, content: contentParsed };
}
