import styled from '@emotion/styled';
import { Drawer, ListItemText } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';

export const CustomDrawer = styled(Drawer)`
  & .MuiDrawer-docked {
    height: 100%;
    position: relative;
  }
  & .MuiList-padding {
    padding-top: initial;
    padding-bottom: initial;
  }
  & .MuiDrawer-paper {
    top: initial;
    position: initial;
    min-width: 300px;
    height: 100%;
  }
  & .MuiToolbar-regular {
    min-height: initial;
  }
  & .MuiTreeItem-root {
    padding: 8px 16px 8px 16px;
  }
  & .MuiTreeItem-iconContainer {
    justify-content: flex-start;
    margin-right: -4px;
  }
  & .MuiTreeItem-label {
    text-align: start;
    justify-content: space-between;
    padding-left: initial;
  }
  & .MuiTreeItem-iconContainer svg {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.54);
  }
  & .group-title {
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: bold;
  }
  & .box-label {
    padding-left: 31px;
  }
  & .MuiTreeItem-content {
    flex-direction: row-reverse;
    .MuiSvgIcon-root {
      opacity: 0.54;
    }
  }
  & .Mui-selected {
    background-color: initial;
    border: 5px;
    border-color: initial;
  }
`;

export const CustomDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ErrorTreeItem = styled(TreeItem)`
  color: #ff6347;
`;

export const ErrorListItemText = styled(ListItemText)`
  color: #ff6347;
`;

export const CenterDrawer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
