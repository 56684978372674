import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import SettingsIcon from '@material-ui/icons/Settings';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DrawerPanel from 'components/DrawerPanel/DrawerPanel';
import { DrawerUpRow } from 'components/DrawerPanel/DrawerPanel.type';
import { Roles } from 'enums/roles';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Dispatch } from 'redux';
import { AppDispatch } from 'store';
import {
  addSpace,
  deleteOrganizationSettings,
  fetchSettings,
  getSettings,
  setClientId,
  setCurrentOrganizationSettings,
} from 'store/settings/settings.actions';
import { getSettingsSelector as fetchSettingsSelector } from 'store/settings/settings.selectors';
import { getUserDataSelector } from 'store/user/user.selectors';
import { checkRoles } from 'utils/checkRoles';
import { useTypedSelector } from 'utils/hooks/TypedSelector';
import { SettingsDTO } from 'utils/restApplicationClient';
import SettingsFormContainer from './SettingsFormContainer';
import {
  DangerButton,
  ErrorText,
  FullWidth,
  LeftSide,
  SettingsViewContainer,
} from './SettingsViewContainer.style';

const SettingViewContainer = (): JSX.Element => {
  const { t } = useTranslation();
  const settings = useTypedSelector(fetchSettingsSelector);
  const currentUser = useTypedSelector(getUserDataSelector);
  const dispatch = useDispatch<Dispatch<AppDispatch>>();
  const isAdmin = checkRoles([Roles.ADMIN_XTM], currentUser.roles as Roles[]);
  const navigate = useHistory();
  const [settingsIndex, setSettingsIndex] = useState<number | undefined>();
  const [organizationIndex, setOrganizationIndex] = useState<
    number | undefined
  >();

  const [displayDialog, setDisplayDialog] = useState<boolean>(false);

  const {
    params: { clientId },
  } = useRouteMatch<{ clientId: string }>();

  const bottomButtons = [
    {
      label: t('common.addSpace'),
      icon: <AddCircleIcon />,
      onClick: (): void => {
        dispatch(addSpace(true));
        if (isAdmin) dispatch(setClientId(clientId));
        navigate.push('/settings/connect');
      },
    },
  ];

  const options = (): DrawerUpRow[] => {
    if (isAdmin) {
      return [
        {
          label: t('settings.backToSettings'),
          icon: <ArrowBackIosIcon />,
          onClick: (): void => {
            navigate.push('/settings');
          },
        },
      ];
    } else {
      return [
        {
          label: t('common.organizationSettings'),
          icon: <SettingsIcon />,
        },
      ];
    }
  };

  const group = useMemo(() => {
    return settings.map((data: SettingsDTO, index: number) => {
      return {
        elements: [
          {
            label: data.spaceName,
            icon: <FolderOpenIcon />,
            onClick: (): void => {
              setSettingsIndex(index);
            },
            error: data.apiKeyError,
            elements: data.organizationSettingsDTOList.map((data, index) => {
              return {
                label: data.environmentId,
                icon: <CallSplitIcon />,
                onClick: (): void => {
                  dispatch(setCurrentOrganizationSettings(data));
                  setOrganizationIndex(index);
                },
              };
            }),
          },
        ],
      };
    });
  }, [settings]);

  const enableEntryLevelLocalization = settings.map(
    (data) => data.enabledEntryLevelLocalization,
  );

  useEffect(() => {
    if (isAdmin) {
      dispatch(getSettings(clientId));
    } else {
      dispatch(fetchSettings());
    }
  }, [isAdmin]);

  const deleteSpace = (index: number): void => {
    setDisplayDialog(false);
    dispatch(deleteOrganizationSettings(settings[index].settingsId));
    navigate.push('/settings/connect');
  };

  const handleDisplay = (): void => {
    setDisplayDialog(true);
  };

  const handleClose = (): void => {
    setDisplayDialog(false);
  };

  const handleCredentials = (): void => {
    dispatch(addSpace(true));
    navigate.push('/settings/reconnect');
  };

  return (
    <SettingsViewContainer>
      <DrawerPanel
        group={group}
        topElements={options()}
        bottomElements={bottomButtons}
      />

      {settingsIndex !== undefined && organizationIndex !== undefined && (
        <FullWidth>
          <SettingsFormContainer
            enabledEntryLevelLocalization={
              enableEntryLevelLocalization[settingsIndex]
            }
            disable={settings[settingsIndex].apiKeyError}
            clientId={clientId === 'successful' ? undefined : clientId}
          />
          <LeftSide>
            {settings[settingsIndex].apiKeyError ? (
              <ErrorText>{t(`settings.errors.apiError`)}</ErrorText>
            ) : undefined}
            <Button
              startIcon={<VpnKeyIcon />}
              onClick={(): void => handleCredentials()}
            >
              {t('settings.buttons.changeCredentials')}
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              onClick={(): void => handleDisplay()}
            >
              {t('settings.buttons.deleteSpace')}
            </Button>
          </LeftSide>
        </FullWidth>
      )}
      <Dialog open={displayDialog} onClose={(): void => handleClose()}>
        <DialogContent>
          <DialogTitle>{t('settings.deleteTitle')}</DialogTitle>
          <DialogContentText>
            {' '}
            {t('settings.deleteConfirmation')}{' '}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(): void => handleClose()}>
            {t('common.cancel')}
          </Button>
          {settingsIndex !== undefined && (
            <DangerButton
              variant="outlined"
              onClick={(): void => deleteSpace(settingsIndex)}
            >
              {t('common.delete')}
            </DangerButton>
          )}
        </DialogActions>
      </Dialog>
    </SettingsViewContainer>
  );
};
export default SettingViewContainer;
