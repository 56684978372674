import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, pluck, switchMap } from 'rxjs/operators';
import store from 'store';
import { showError, showSuccessful } from 'store/notify/notify.actions';
import { setPaginationSearchSpinner } from 'store/table/table.actions';
import { api } from 'utils/axiosInstance';
import browserHistory from 'utils/browserHistory';
import { SaveOrganizationSettingsDTO } from 'utils/restApplicationClient';
import {
  addSpace,
  changeCredentials,
  changeCredentialsError,
  changeCredentialsSuccess,
  deleteOrganizationSettings,
  deleteOrganizationSettingsError,
  deleteOrganizationSettingsSuccess,
  fetchSettings,
  fetchSettingsError,
  fetchSettingsSuccess,
  getAllSettings,
  getAllSettingsError,
  getAllSettingsSuccess,
  getSettings,
  getSettingsError,
  getSettingsSuccess,
  initializationCheck,
  initializationCheckSuccess,
  initializeContentful,
  initializeContentfulError,
  initializeContentfulSuccess,
  setClientId,
  updateSettings,
  updateSettingsError,
  updateSettingsSuccess,
} from './settings.actions';

const initializeContentfulSettingsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(initializeContentful),
    switchMap(({ payload }) =>
      from(api.initializeNewSettings(payload)).pipe(
        switchMap(({ data, data: [{ settingsId }] }) => {
          store.dispatch(
            showSuccessful({ message: 'settings.success.initialize' }),
          );
          browserHistory.push(`/settings/connect/${settingsId}`);
          return of(initializeContentfulSuccess(data));
        }),
        catchError(
          ({
            response: {
              data: { errorCode: message },
            },
          }) => {
            return of(initializeContentfulError(message));
          },
        ),
      ),
    ),
  );
const changeCredentialsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(changeCredentials),
    switchMap(({ payload }) =>
      from(api.updateSettingsCredentials(payload)).pipe(
        switchMap(() => {
          store.dispatch(
            showSuccessful({ message: 'settings.success.changeCredentials' }),
          );
          browserHistory.push(`/settings/connect/success`);
          return of(changeCredentialsSuccess());
        }),
        catchError(() => {
          store.dispatch(
            showError({ message: 'settings.errors.changeCredentials' }),
          );
          return of(changeCredentialsError(undefined));
        }),
      ),
    ),
  );

const initializationCheckEpic: Epic = (action$) =>
  action$.pipe(
    ofType(initializationCheck),
    switchMap(() =>
      from(api.isSettingsInitialized$GET$api_settings_isinitizalized()).pipe(
        switchMap(({ data }) => {
          return of(initializationCheckSuccess(data));
        }),
        catchError(() => {
          store.dispatch(showError({ message: 'settings.errors.initialize' }));
          return of(initializeContentfulError(undefined));
        }),
      ),
    ),
  );

const deleteOrganizationSettingsEpic: Epic = (action$, _, { browserHistory }) =>
  action$.pipe(
    ofType(deleteOrganizationSettings),
    switchMap(({ payload }) =>
      from(api.deleteOrganizationSettings(payload)).pipe(
        switchMap(() => {
          store.dispatch(
            showSuccessful({ message: 'settings.success.delete' }),
          );
          browserHistory.go(0);
          return of(
            deleteOrganizationSettingsSuccess('settings.successes.deletion'),
          );
        }),
        catchError(() => {
          store.dispatch(showError({ message: 'settings.errors.cantDelete' }));
          return of(deleteOrganizationSettingsError(undefined));
        }),
      ),
    ),
  );

const fetchSettingsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(fetchSettings),
    switchMap(() =>
      from(api.fetchSettings()).pipe(
        switchMap(({ data }) => of(fetchSettingsSuccess(data))),
        catchError(() => of(fetchSettingsError())),
      ),
    ),
  );
export const getAllSettingsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getAllSettings.type),
    switchMap(({ payload }) =>
      from(api.getAllSettings(payload)).pipe(
        switchMap(({ data }) => {
          store.dispatch(setPaginationSearchSpinner(false));
          return of(getAllSettingsSuccess(data));
        }),
        catchError(() => {
          store.dispatch(setPaginationSearchSpinner(false));
          return of(getAllSettingsError());
        }),
      ),
    ),
  );

const getSettingsEpic: Epic = (action$, _, { browserHistory }) =>
  action$.pipe(
    ofType(getSettings.type),
    switchMap(({ payload }) =>
      from(api.getSettings(payload)).pipe(
        switchMap(({ data }) => {
          if (data.length === 0) {
            browserHistory.push(`/settings/connect`);
            return of(
              addSpace(true),
              setClientId(payload),
              getSettingsSuccess(data),
            );
          }
          return of(getSettingsSuccess(data));
        }),
        catchError(() => of(getSettingsError())),
      ),
    ),
  );

const updateSettingsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(updateSettings.type),
    pluck<PayloadAction<SaveOrganizationSettingsDTO>, 'payload'>('payload'),
    switchMap((payload) =>
      from(api.saveSettings(payload)).pipe(
        switchMap(() => {
          return of(
            updateSettingsSuccess(),
            showSuccessful({ message: 'settings.success.updateSettings' }),
          );
        }),
        catchError(() =>
          of(
            updateSettingsError(),
            showError({ message: 'settings.errors.updateSettings' }),
          ),
        ),
      ),
    ),
  );

export const settingsEpics = combineEpics(
  initializeContentfulSettingsEpic,
  initializationCheckEpic,
  deleteOrganizationSettingsEpic,
  fetchSettingsEpic,
  getAllSettingsEpic,
  getSettingsEpic,
  updateSettingsEpic,
  changeCredentialsEpic,
);
