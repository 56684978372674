import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import store from 'store';
import { showError, showSuccessful } from 'store/notify/notify.actions';
import { setPaginationSearchSpinner } from 'store/table/table.actions';
import { sessionExpired } from 'store/user/user.actions';
import { api } from 'utils/axiosInstance';
import {
  createClient,
  createClientError,
  createClientSuccess,
  editClient,
  editClientSuccess,
  getAllClients,
  getAllClientsError,
  getAllClientsSuccess,
  getAllLanguages,
  getAllLanguagesError,
  getAllLanguagesSuccess,
  getClientById,
  getClientByIdError,
  getClientByIdSuccess,
  getClientsPageable,
  getClientsPageableError,
  getClientsPageableSuccess,
} from './client.actions';

const getAllClientsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getAllClients.type),
    switchMap(() =>
      from(api.getAllClients$GET$api_client()).pipe(
        switchMap(({ data }) => of(getAllClientsSuccess(data))),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }

          store.dispatch(showError({ message: 'client.errors.get' }));
          return of(getAllClientsError());
        }),
      ),
    ),
  );

const createClientEpic: Epic = (action$, _, { browserHistory }) =>
  action$.pipe(
    ofType(createClient.type),
    switchMap(({ payload }) =>
      from(api.createClient(payload)).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'client.success.create' }));
          browserHistory.push('/clients');
          return of(createClientSuccess());
        }),
        catchError(({ response: { status } }) => {
          store.dispatch(showError({ message: 'client.errors.create' }));
          if (status === 409) {
            return of(createClientError('client.errors.duplicate'));
          } else if (status === 403) {
            return of(sessionExpired(true));
          }

          return of(createClientError(undefined));
        }),
      ),
    ),
  );

const getClientsPageableEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getClientsPageable.type),
    switchMap(({ payload }) =>
      from(api.getAllClients$GET$api_client_pageable(payload)).pipe(
        switchMap(({ data }) => {
          store.dispatch(setPaginationSearchSpinner(false));
          return of(getClientsPageableSuccess(data));
        }),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }

          store.dispatch(setPaginationSearchSpinner(false));
          store.dispatch(showError({ message: 'client.errors.get' }));
          return of(getClientsPageableError());
        }),
      ),
    ),
  );

const editClientEpic: Epic = (actions$, _, { browserHistory }) =>
  actions$.pipe(
    ofType(editClient.type),
    switchMap(({ payload }) =>
      from(api.editClient(payload.clientId, payload.updateClient)).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'client.success.edit' }));
          browserHistory.push('/clients');
          return of(editClientSuccess());
        }),
        catchError(({ response: { status } }) => {
          store.dispatch(showError({ message: 'client.errors.edit' }));
          if (status === 409) {
            return of(createClientError('client.errors.duplicate'));
          } else if (status === 403) {
            return of(sessionExpired(true));
          }

          return of(createClientError(undefined));
        }),
      ),
    ),
  );

const getClientByIdEpic: Epic = (actions$, _, { browserHistory }) =>
  actions$.pipe(
    ofType(getClientById.type),
    switchMap(({ payload }) =>
      from(api.getClient(payload)).pipe(
        switchMap(({ data }) => of(getClientByIdSuccess(data))),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }

          browserHistory.push('/clients');
          store.dispatch(showError({ message: 'client.errors.noId' }));
          return of(getClientByIdError());
        }),
      ),
    ),
  );

const getAllLanguagesEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(getAllLanguages.type),
    switchMap(() =>
      from(api.getAllContentfulAndXtmLanguages()).pipe(
        switchMap(({ data }) => of(getAllLanguagesSuccess(data))),
        catchError(() => {
          return of(
            getAllLanguagesError(),
            showError({ message: 'client.errors.languages.xtm' }),
          );
        }),
      ),
    ),
  );

export const clientEpics = combineEpics(
  getClientsPageableEpic,
  getAllClientsEpic,
  createClientEpic,
  editClientEpic,
  getClientByIdEpic,
  getAllLanguagesEpic,
);
