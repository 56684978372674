import styled from '@emotion/styled';
import { Button } from '@material-ui/core';

export const SettingsViewContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
export const SettingsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px;
`;
export const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const CustomMappingFieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 12px 8px 0;
  width: 100%;
`;

export const CustomMappingContainer = styled.fieldset`
  margin: 20px -16px;
  display: flex;
  flex-direction: column;
  padding: 16px 13px 16px 15px;
  border: 1px solid #6f8294;
  width: 100%;
`;

export const CustomMappingLegend = styled.legend`
  text-align: initial;
  font-weight: 700;
  font-size: 14px;
  color: #333;
  padding: 0 4px;
  margin-left: -5px;
`;

export const FullWidth = styled.div`
  width: 100%;
  padding-left: 24px;
`;

export const ButtonsRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0px 20px;
`;

export const DangerButton = styled(Button)`
  color: rgb(220, 0, 78) !important;
  border: 1px solid rgba(220, 0, 78, 0.23) !important;
`;

export const LeftSide = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ButtonView = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 16px;
`;

export const ErrorText = styled.span`
  color: #ff6347;
`;
