import { createAction } from '@reduxjs/toolkit';
import { IEditClient, IPaginationOptions } from 'types/api';
import {
  AllLanguagesDTO,
  ClientDTO,
  CreateClientDTO,
  Page,
} from 'utils/restApplicationClient';
import { ClientActionTypes } from './client.actions.types';

export const getAllClients = createAction(ClientActionTypes.GetAllClients);
export const getAllClientsError = createAction(
  ClientActionTypes.GetAllClientsError,
);
export const getAllClientsSuccess = createAction<ClientDTO[]>(
  ClientActionTypes.GetAllClientsSuccess,
);
export const createClient = createAction<CreateClientDTO>(
  ClientActionTypes.CreateClient,
);
export const createClientSuccess = createAction(
  ClientActionTypes.CreateClientSuccess,
);
export const createClientError = createAction<string | undefined>(
  ClientActionTypes.CreateClientError,
);

export const getClientsPageable = createAction<IPaginationOptions>(
  ClientActionTypes.GetClientsPageable,
);
export const getClientsPageableSuccess = createAction<Page<ClientDTO>>(
  ClientActionTypes.GetClientsPageableSuccess,
);
export const getClientsPageableError = createAction(
  ClientActionTypes.GetClientsPageableError,
);
export const editClient = createAction<IEditClient>(
  ClientActionTypes.EditClient,
);
export const editClientSuccess = createAction(
  ClientActionTypes.EditClientSuccess,
);
export const editClientError = createAction(ClientActionTypes.EditClientError);

export const getClientById = createAction<string>(
  ClientActionTypes.GetClientById,
);
export const getClientByIdSuccess = createAction<ClientDTO>(
  ClientActionTypes.GetClientByIdSuccess,
);
export const getClientByIdError = createAction(
  ClientActionTypes.GetClientByIdError,
);

export const clearClient = createAction(ClientActionTypes.ClearClient);

export const clearClientErrors = createAction(
  ClientActionTypes.ClearClientErrors,
);

export const getAllLanguages = createAction(ClientActionTypes.GetXTMLanguages);

export const getAllLanguagesSuccess = createAction<AllLanguagesDTO>(
  ClientActionTypes.GetXTMLanguagesSuccess,
);

export const getAllLanguagesError = createAction(
  ClientActionTypes.GetXTMLanguagesError,
);
