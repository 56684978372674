import { OptionsObject } from 'notistack';

export const NotificationActionTypes = {
  showError: 'SHOW_ERROR',
  closeAlert: 'CLOSE_ALERT',
  showSuccessful: 'SHOW_SUCCESSFUL',
};

export interface IShowErrorPayload {
  message: string;
  options?: OptionsObject;
}

export interface ICloseAlertPayload {
  key: number;
}
