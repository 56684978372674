import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';
import { IPaginationOptions } from 'types/api';
import { TableActionTypes } from './table.actions.types';
import { ITablePaginationOptions } from './table.interface';

type TableConstantsTypes = typeof TableActionTypes;

export const setPaginationOptions = createAction<
  ITablePaginationOptions,
  TableConstantsTypes['setPaginationOptions']
>(TableActionTypes.setPaginationOptions);

export const clearPaginationOptions = createAction(
  TableActionTypes.clearPaginationOptions,
);

export const setPaginationSearch = createAction<
  string,
  TableConstantsTypes['setPaginationSearch']
>(TableActionTypes.setPaginationSearch);

export const setPaginationAction = createAction<
  ActionCreatorWithPayload<IPaginationOptions> | undefined,
  TableConstantsTypes['setPaginationAction']
>(TableActionTypes.setPaginationAction);

export const setPaginationSearchSpinner = createAction<
  boolean,
  TableConstantsTypes['setPaginationSearchSpinner']
>(TableActionTypes.setPaginationSearchSpinner);
